// servicesData.js
import { useMemo } from 'react';

export const useServicesData = () => {

    return useMemo(() => {
        const servicesRoute = '/servicios';

        return [
            { src: "img/HOMEPAGE/1_PLANES.webp", alt: "CARTERA DE SERVICIOS", name: "CARTERA DE SERVICIOS", route: servicesRoute + '#carteraServicios' },
            { src: "img/HOMEPAGE/2_ASESORIAS.webp", alt: "ASESORÍAS", name: "ASESORÍAS", route: servicesRoute + '#asesorias' },
            { src: "img/HOMEPAGE/3_MASTER-PLAN.webp", alt: "MASTER PLAN", name: "MASTER PLAN", route: servicesRoute + '#masterPlan' },
        ];
    }, []);
};