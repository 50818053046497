import React from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import './CardComponent.css';

export function CardComponent({ route, src, name, cardClass }) {
    const navigate = useNavigate();

    return (
    <Card className={cardClass} onClick={() => navigate(route)}>
        <Card.Img className="fluid-img" src={src} alt={name} />
        <Card.Title>{name}</Card.Title>
    </Card>
    );
}