import React from 'react';
import Col from 'react-bootstrap/Col';
import './ActionItem.css';

const ActionItem = ({ text }) => {
    return (
        <Col xs={4} className="master-plan-action-item">
            <h2 className="action-text">{text}</h2>
        </Col>
    );
};

export default ActionItem;