import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useProjectEffects } from '../../utils/useProjectEffects';
import './CustomNavbar.css';

function CustomNavbar() {
    const [expanded, setExpanded] = useState(false);
    const windowWidth = useProjectEffects(expanded);
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavClick = (event, route) => {
        const isModifierPressed = event.button === 1 || event.ctrlKey || event.metaKey || event.shiftKey;
        if (!isModifierPressed) {
            event.preventDefault();
            navigate(route);
            setExpanded(false);
        }
    };

    const toggleMenu = () => {
        setExpanded(!expanded);
    };

    const routes = {
        HomePage: '/',
        Projects: '/proyectos',
        Services: '/servicios',
        Contact: '/contacto'
    };

    return (
        <Navbar expand="lg" className={expanded ? 'expanded' : ''}>
            <Container className='navbar-custom-container'>
                {expanded ? <LazyLoadImage  src="../logo-3-er-1.webp" alt="Estudio Regular Logo" className="logo d-inline-block align-top"/> : ''}
                <Navbar.Brand xs={1} md={1} xl={2} onClick={(e) => handleNavClick(e, routes.HomePage)}>
                    <LazyLoadImage  src="../logo-3-er-1.webp" alt="Estudio Regular Logo" className={`${expanded ? "logo d-inline-block align-top" : "logo-hided logo" }`}/>
                </Navbar.Brand>
                <div className={`hamburger-btn d-lg-none ${expanded ? 'active' : ''}`} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <Navbar.Collapse id="basic-navbar-nav" className={`navbar-collapse-custom ${expanded ? 'show' : ''}`}>
                    <Nav className="nav-buttons">
                    <Nav.Link href={routes.HomePage} className={`nav-link-custom ${location.pathname === routes.HomePage ? 'active' : ''}`} onClick={(e) => handleNavClick(e, routes.HomePage)}>inicio</Nav.Link>
                    <Nav.Link href={routes.Projects} className={`nav-link-custom ${location.pathname === routes.Projects ? 'active' : ''}`} onClick={(e) => handleNavClick(e, routes.Projects)}>proyectos</Nav.Link>
                    <Nav.Link href={routes.Services} className={`nav-link-custom ${location.pathname === routes.Services ? 'active' : ''}`} onClick={(e) => handleNavClick(e, routes.Services)}>servicios</Nav.Link>
                    <Nav.Link href={routes.HomePage + '#nosotros'} className={`nav-link-custom ${location.pathname === routes.HomePage + '#nosotros' ? 'active' : ''}`} onClick={(e) => handleNavClick(e, routes.HomePage + '#nosotros')}>nosotros</Nav.Link>
                    <Nav.Link href={routes.Contact} className={`nav-link-custom ${location.pathname === routes.Contact ? 'active' : ''}`} onClick={(e) => handleNavClick(e, routes.Contact)}>contacto</Nav.Link>
                        {expanded && (
                            <Button variant="primary" size="lg" className="btn-start-custom" onClick={(e) => handleNavClick(e, routes.Contact)}>¡Comencemos!</Button>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CustomNavbar;