// imageHandler.js

export const downloadImage = async (previewUrl, newName) => {
    try {
        console.log('Downloading image:', previewUrl);
        const response = await fetch(previewUrl);
        if (!response.ok) {
            throw new Error(`Error downloading image: ${response.statusText}`);
        }
        const blob = await response.blob();
        return new File([blob], newName, { type: blob.type });
    } catch (error) {
        console.error('Error downloading image:', error);
        throw error;
    }
};

export const downloadAndUploadImage = async (previewUrl, newName, projectName) => {

    const file = await downloadImage(previewUrl, newName);
    console.log('Downloaded image file:', file);

    // Aquí asegúrate de que 'newName' sea el nombre del archivo, y 'projectName' sea el nombre del proyecto.
    return uploadImage(file, newName, projectName);  // Pasa correctamente el 'newName' y el 'projectName'
};

export const uploadImage = async (file, newName, projectName) => {
    const formData = new FormData();
    
    // Aquí usamos el `newName` que ya tenemos
    console.log('Uploading file:', file);
    formData.append('images', file, newName);
    formData.append('projectName', projectName);

    console.log('FormData contents:', formData.get('images'), formData.get('projectName'));

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: formData,
        });
        
        const contentType = response.headers.get('content-type');
        
        if (contentType && contentType.includes('application/json')) {
            const responseData = await response.json();
            if (!response.ok) {
                console.error('Failed to upload image:', responseData);
                throw new Error(`Failed to upload image: ${responseData.message || response.statusText}`);
            }
            console.log('Image uploaded and converted successfully!');
            return responseData.paths;
        } else {
            const responseText = await response.text();
            console.error('Expected JSON, but received:', responseText);
            throw new Error('Unexpected response format: ' + responseText);
        }
    } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
    }
};

export const uploadProjectImages = async (projectData, savedGroups) => {
    const projectNameForPath = projectData.name.toUpperCase().replace(/ /g, '_');
    const formattedProjectName = projectData.name.toUpperCase().replace(/ /g, '-');

    try {
        // Subir las imágenes de encabezado, principal y miniatura
        const headerImageUploadPromise = downloadAndUploadImage(projectData.headerImageFile.blob, projectData.header_image, projectNameForPath);
        console.log('Subiendo imagen principal');
        const mainImageUploadPromise = downloadAndUploadImage(projectData.mainImageFile.blob, projectData.main_image, projectNameForPath);
        console.log('Subiendo imagen miniatura');
        const thumbnailImageUploadProjectPromise = downloadAndUploadImage(projectData.thumbnailImageFile.blob, projectData.thumbnail_image_projects, 'PROJECTS');
        const thumbnailImageUploadHomePagePromise = downloadAndUploadImage(projectData.thumbnailImageFile.blob, projectData.thumbnail_image_homepage, 'HOMEPAGE');

        let globalIndex = 2; // Iniciamos en 2 porque 1 está asignado a la imagen principal

        const layoutImageUploadPromises = savedGroups.flatMap(group => 
            group.images.map(async (image) => {
                const extension = image.alt.split('.').pop();
                const newName = `${globalIndex}_${formattedProjectName}.${extension}`;
                globalIndex++;
                await downloadAndUploadImage(image.preview || image.src, newName, projectNameForPath);
            })
        );

        await Promise.all([headerImageUploadPromise, mainImageUploadPromise, thumbnailImageUploadProjectPromise, thumbnailImageUploadHomePagePromise, ...layoutImageUploadPromises]);
    } catch (error) {
        console.error('Error uploading images:', error);
        alert('Error subiendo la imagen:', error);
        throw error;
    }
};

export const uploadImageWithOverwriteOption = async (file, projectName, originalName, useOverwrite) => {
    const formData = new FormData();
    formData.append('images', file);  // Imagen a subir
    formData.append('projectName', projectName);  // Nombre del proyecto
    formData.append('originalName', originalName);  // Nombre original de la imagen a reemplazar
    formData.append('useOverwrite', useOverwrite);  // Bandera para saber si sobrescribir

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: formData,
        });

        const responseData = await response.json();
        if (!response.ok) {
            console.error('Error uploading image:', responseData);
            throw new Error(`Error: ${responseData.message}`);
        }
        console.log('Image uploaded successfully:', responseData);
        return responseData;
    } catch (error) {
        console.error('Error during image upload:', error);
        throw error;
    }
};