import React from 'react';
import Form from 'react-bootstrap/Form';

const FormField = ({ controlId, label, type, placeholder, onChange, as, rows, readOnly, defaultValue, value, maxLength=null }) => (
    <Form.Group controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control 
            defaultValue={defaultValue}
            type={type} 
            placeholder={placeholder} 
            onChange={onChange} 
            as={as} 
            rows={rows} 
            readOnly={readOnly} 
            value={value} 
            maxLength={maxLength}
        />
        {maxLength && (
            <Form.Text className="text-muted">
                {`${value.length}/${maxLength}`}
            </Form.Text>
        )}
    </Form.Group>
);

export default FormField;