import { useEffect, useState } from 'react';
import { setScreenWidthVariable } from '../utils/setScreenWidthVariable';

export const useProjectEffects = (expanded) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (expanded) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [expanded]);

    useEffect(() => {
        setScreenWidthVariable();
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        handleResize();
        return () => { window.removeEventListener('resize', handleResize); }
    }, []);

    return windowWidth;
};