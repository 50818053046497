import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import './AuthorizeUsers.css';
import Loader from '../../../../components/Loader';

function AuthorizeUsers() {
    const [users, setUsers] = useState([]);
    const token = localStorage.getItem('token');
    const apiUrl = process.env.REACT_APP_API_URL;
    const [showSuccessModal, setShowSuccessModal] = useState(false);


    const handleSubmit = async (event, user_id) => {
        event.preventDefault();
        try {
            const response = await fetch(`${apiUrl}/api/admin/authorize/${user_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            const data = await response.json();
            if (response.ok) {
                console.log('Usuario autorizado:', data.message);
                setShowSuccessModal(true);
            } else {
                console.error('Error al autorizar usuario:', data.message);
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
        }
    };

    const handleDelete = async (event, user_id) => {
        event.preventDefault();
        try {
            const response = await fetch(`${apiUrl}/api/admin/deleteById/${user_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                console.log('Usuario eliminado:', data.message);
            } else {
                console.error('Error al eliminar usuario:', data.message);
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
        }
    }

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/admin/getAll`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                console.error('Error al obtener los usuarios:', error);
            }
        };
        fetchUsers();
    }, [apiUrl, token]);

    return (
        <div>
            <Container className='my-5'>
                <Row>
                    <Col xs={12}>
                        <h2>Usuarios</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} >
                    {users.users && users.users.length > 0 ? (
                        users.users.map(user => (
                            <Card key={user.id} className='user-card'>
                                <Card.Body>
                                    <Card.Title className='user-card-title'>{user.user_name}</Card.Title>
                                    <Card.Text className='user-card-text'>{user.name} {user.last_name}</Card.Text>
                                    <Card.Text className='user-card-text'>{user.email}</Card.Text>
                                </Card.Body>
                                <Card.Footer className='user-card-footer'>
                                <Button className='authorization-button' onClick={(event) => handleSubmit(event, user.id)}  disabled={user.is_authorized === 1}>
                                    {user.is_authorized === 1 ? 'Autorizado' : 'Autorizar'}
                                </Button>
                                <Button className='modal-button-quit' onClick={(event) => handleDelete(event, user.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6zm3.6 5q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"/></svg>
                                </Button>
                                </Card.Footer>
                            </Card>
                        ))
                    ) : (
                        <Loader />
                    )}
                    </Col>
                </Row>
            </Container>
            <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Éxito</Modal.Title>
                </Modal.Header>
                <Modal.Body>El usuario ha sido autorizado con éxito.</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseSuccessModal}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AuthorizeUsers;
