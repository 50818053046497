import React from "react";
import App from './App';
import { createRoot } from 'react-dom/client';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';


const rootElement = createRoot(document.getElementById("root"));
rootElement.render(
    
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

