import React from 'react';
import Col from 'react-bootstrap/Col';
import ImagePreview from './ImagePreview';

function ImageSelection({ images, selectedImages, layouts, selectedLayout, toggleImageSelection, removeImage }) {
    return (
        <div className="image-selection">
            {images.map((image, index) => {
                const isSelected = selectedImages.includes(image);
                const imageClass = `image-item ${isSelected ? 'selected' : ''}`;
                return (
                    <Col xs={3} key={index} className={imageClass} onClick={() => toggleImageSelection(image)}>
                        <ImagePreview 
                            imageWidth={12} 
                            removeImage={() => removeImage(index)} 
                            className='left-images' 
                            image={image} 
                            index={index} 
                            color='#6e132a' 
                        />
                    </Col>
                );
            })}
        </div>
    );
}

export default ImageSelection;