// InstagramPosts.jsx
import React from 'react';
import { useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const Mute = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="30px" viewBox="0 0 16 16">
      <path d="M8 2.81v10.38c0 .67-.81 1-1.28.53L3 10H1c-.55 0-1-.45-1-1V7c0-.55.45-1 1-1h2l3.72-3.72C7.19 1.81 8 2.14 8 2.81zm7.53 3.22l-1.06-1.06l-1.97 1.97l-1.97-1.97l-1.06 1.06L11.44 8L9.47 9.97l1.06 1.06l1.97-1.97l1.97 1.97l1.06-1.06L13.56 8l1.97-1.97z" 
        fill={color}/>
      </svg>
  )
}

const Unmute = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="30px" viewBox="0 0 16 16">
      <path d="M12 8.02c0 1.09-.45 2.09-1.17 2.83l-.67-.67c.55-.56.89-1.31.89-2.16c0-.85-.34-1.61-.89-2.16l.67-.67A3.99 3.99 0 0 1 12 8.02zM7.72 2.28L4 6H2c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h2l3.72 3.72c.47.47 1.28.14 1.28-.53V2.81c0-.67-.81-1-1.28-.53zm5.94.08l-.67.67a6.996 6.996 0 0 1 2.06 4.98c0 1.94-.78 3.7-2.06 4.98l.67.67A7.973 7.973 0 0 0 16 8c0-2.22-.89-4.22-2.34-5.66v.02zm-1.41 1.41l-.69.67a5.05 5.05 0 0 1 1.48 3.58c0 1.39-.56 2.66-1.48 3.56l.69.67A5.971 5.971 0 0 0 14 8.02c0-1.65-.67-3.16-1.75-4.25z" 
      fill={color}/>
    </svg>
  )
}

const InstagramPosts = ({ children, instagramPostsUrls }) => {
  const [mutedVideos, setMutedVideos] = useState(instagramPostsUrls.map(() => true));

  const handleMuteToggle = (index, videoRef) => {
    if (videoRef.current) {
      const updatedMutedState = !videoRef.current.muted;
      videoRef.current.muted = updatedMutedState;

      setMutedVideos((prevState) => {
        const newMutedStates = [...prevState];
        newMutedStates[index] = updatedMutedState;
        return newMutedStates;
      });
    }
  };

  const handleVideoClick = (e, postUrl) => {
    if (e.target.tagName !== 'BUTTON') {
      window.open(postUrl, "_blank");  
    }
  };

  return (
    <Col xs={12} xl={4} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
      {instagramPostsUrls.map((postUrl, index) => {
        const videoUrl = `vid/${index + 1}_VIDEO.mp4`;
        const videoRef = useRef(null);
        const isMuted = mutedVideos[index];

        return (
          <Col key={index} xs={6} md={6} xl={6} className='images-phone'>
            <Card
              onMouseEnter={() => videoRef.current.play()}
              onMouseLeave={() => videoRef.current.pause()}
              onClick={(e) => handleVideoClick(e, postUrl)}
            >
              <Card.Body style={{padding:'5px', border:'none'}}>
                <video
                  ref={videoRef}
                  type="video/mp4"
                  loop
                  muted={isMuted}
                  src={videoUrl}
                  onLoadedMetadata={(e) => {
                    const duration = Math.min(e.target.duration, 10);
                    setTimeout(() => {
                      e.target.pause();
                    }, duration * 1000);
                  }}
                  style={{ width: '100%', height: '100%', borderRadius: '5px', cursor: 'pointer' }}
                >
                  <source src={videoUrl} type="video/mp4" />
                  Tu navegador no soporta la etiqueta de video.
                </video>
                <Button
                  variant="secondary"
                  style={{
                    position: 'absolute',
                    bottom: '15%',
                    left: '40%',
                    backgroundColor: '#6e132a',
                    border: 'none',
                    zIndex: 1
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMuteToggle(index, videoRef);
                  }}
                  className='mute-button'
                >
                  {isMuted ? <Mute color='#fafafa' /> : <Unmute color='#fafafa' />}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
      {children}
    </Col>
  );
};

export default InstagramPosts;