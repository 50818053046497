import React, { useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Services.css';
import CustomNavbar from '../../components/CustomNavbar/CustomNavbar';
import CustomHyperLinksSection from './CustomHyperLinksSection';
import SectionTitle from '../HomePage/SectionTitle';
import CarteraDeServicios from './CarteraDeServicios';
import CardsAsesoriasSection from './CardsAsesoriasSection';
import InstagramPost from './InstagramPost';
import FollowUs from './FollowUs';
import MasterPlanSection from './MasterPlanSection';
import CustomButton from '../../components/CustomButton';
import { handleGoContactPage } from '../../utils/navigateToContact';

const text_empresas = 'Asesoramos a las empresas en sus decisiones de infraestructura, permisología, o modificaciones de innovacion energética.'
const text_terreno_rural = 'Vamos a tu terreno particularmente para ver los límites y posibilidades de tu próximo proyecto a lo largo de las etapas.'
const text_terreno_urbano = 'Maneja toda la información urbana para conocer los componentes de cabida de tu proximo proyecto urbano y determinar sus alcances.'
const text_master_plan = 'Creamos estrategias de planificación para facilitar el desarrollo de la vida humana en equilibrio con las distintas partes del Master. Nuestra experiencia radica en el uso de la ciencia de datos y la simulación virtual, lo que nos permite dar vida a nuestros proyectos potenciando comunidades dinámicas y elevando al máximo la sensación de confort.'
const last_text = 'Consulta por nuestros planes para que puedas llevar tu proceso de infraestructura a lo largo del tiempo.'

const categories = [
    {
        category: 'Diseño',
        options: [
            'Anteproyecto (hasta 65 mts)',
            'Consultorías (Toda escala)',
            'Vivienda',
            'Comercial',
            'Proyecto de Arquitectura'
        ]
    },
    {
        category: 'Especialidades',
        options: [
            'Proyecto de Cálculo Estructural',
            'Proyecto Sanitario',
            'Proyecto Electricidad'
        ]
    },
    {
        category: 'Tramitaciones',
        options: [
            'Permisos de Edificación',
            'Resolución Sanitaria',
            'Declaración TE1',
            'Solicitud de Demolición',
            'Cambio Uso de Suelo (Informe Favorable de Construcción)',
            'Calificación Industrial'
        ]
    }
];

const Services = () => {
    
    const asesorias = [
        {
            title: "EMPRESAS, COMERCIOS & INDUSTRIAS",
            description: text_empresas,
            duration: "1,5 semanas",
            report: "Informe",
            mode: "Modalidad Online"
        },
        {
            title: "TERRENO RURAL",
            description: text_terreno_rural,
            duration: "1,5 semanas",
            report: "Informe",
            mode: "Modalidad Online"
        },
        {
            title: "TERRENO URBANO",
            description: text_terreno_urbano,
            duration: "1,5 semanas",
            report: "Informe",
            mode: "Modalidad Online"
        }
    ];
    
    
    const instagramPostsUrls = [
        'https://www.instagram.com/reel/Cv7ytT3OZvb/?utm_source=ig_embed&amp;utm_campaign=loading',
        'https://www.instagram.com/reel/CpoBmT6OlsQ/?utm_source=ig_embed&amp;utm_campaign=loading',
        'https://www.instagram.com/reel/CwqrdEkgZpG/?utm_source=ig_embed&amp;utm_campaign=loading',
        'https://www.instagram.com/reel/CpWA2p0N7dj/?utm_source=ig_embed&amp;utm_campaign=loading'
    ];

    
    const [expanded, setExpanded] = useState(false)
    

    useEffect(() => {
        if (expanded) {
          // Desactivar scroll cuando el menú está expandido
          document.body.style.overflow = 'hidden';
        } else {
          // Re-activar scroll cuando el menú está cerrado
          document.body.style.overflow = 'auto';
        }
    }, [expanded]); // Dependencia del estado expanded

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const name = hash.substring(1);
            const element = document.getElementById(name);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center'});
            }
        }
    }, [location]);

    return (
        <div>
            <CustomNavbar />
            <Container fluid className="main-image services-main-image text-center bg-light py-5">
            </Container>    
            <Container className="services my-5">
                <h3 className="section-title-services">SERVICIOS</h3>
                
                <CustomHyperLinksSection />

                <Container id="carteraServicios" className="planes-diseño my-5">
                    <SectionTitle title='CARTERA DE SERVICIOS'/>
                    <CarteraDeServicios />
                </Container>

                <Container id="asesorias" className="asesorias my-5">
                    <h3 className="section-title-asesorias">ASESORÍAS</h3>
                    <Row>
                        <CardsAsesoriasSection asesorias={asesorias} />
                        <InstagramPost instagramPostsUrls={instagramPostsUrls}>
                            <FollowUs className={'follow-us'}/>
                        </InstagramPost>
                    </Row>
                </Container>
                <Container id="masterPlan" className="masterPlan my-4">
                    <MasterPlanSection text_master_plan={text_master_plan} onclick={handleGoContactPage('')} />
                </Container>
                <Container id="planesComercio" className='planesComercio'>
                    <Row>
                        <Col xs={12}>
                            <h3 className="upper-text">PLANES COMERCIO & EMPRESAS</h3>
                        </Col>
                    </Row>
                    <Row className='phone-display' style={{justifyContent:'space-evenly'}}>
                        <Col className='button-side' xs={12} md={6}>
                            <CustomButton className="start-button-bottom" onclick={handleGoContactPage('')}>
                                ¡Comencemos!
                            </CustomButton>
                        </Col>
                        <Col className='text-side' xs={12} md={6}>
                            <h3 className="lower-text">{last_text}</h3>
                        </Col>
                    </Row>
                </Container>

                <FollowUs className='social-media-logos-container-bottom'/>
            </Container>
        <Col className="bottom-image-generativa"></Col>
    </div>
  );
};

export default Services;
