export const handleGoContactPage = (message, navigate) => {

    return () => {
        console.log('Go to contact page');
        // Almacenar el mensaje en localStorage o en un estado global para acceder a él en la página de contacto
        localStorage.setItem('contactPlaceholder', message);
        console.log('Message:', message);
        // Navegar a la página de contacto
        navigate('/contacto');
    };
};