import { useNavigate } from 'react-router-dom';

export const useNavigateProjects = () => {
    const navigate = useNavigate();

    const handleRouteClick = (event, dynamicRoutes, direction) => {
        event.preventDefault();
    
        // Filtrar las rutas para incluir sólo las rutas de proyectos
        const projectRoutes = dynamicRoutes.filter(route => route.path.startsWith('/proyectos') && route.path !== '/proyectos');
    
        const routesName = projectRoutes.map(route => route.path);
        const actualPath = window.location.pathname;
        const index = routesName.indexOf(actualPath);
    
        let newIndex = 0;
        if (direction === 'prev') {
            newIndex = index === 0 ? routesName.length - 1 : index - 1;
        } else if (direction === 'next') {
            newIndex = index === routesName.length - 1 ? 0 : index + 1;
        }
    
        const newRoute = routesName[newIndex];
        if (event.button === 0) {
            navigate(newRoute);
        }
    };

    const handleProjectsPrevRouteClick = (event, dynamicRoutes) => {
        handleRouteClick(event, dynamicRoutes, 'prev');
    };

    const handleProjectsNextRouteClick = (event, dynamicRoutes) => {
        handleRouteClick(event, dynamicRoutes, 'next');
    };

    return { handleProjectsPrevRouteClick, handleProjectsNextRouteClick };
};