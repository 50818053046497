import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SectionTitle from './SectionTitle';


const AboutUsSection = ({ text_nosotros, windowWidth }) => {
  return (
    <Container id='nosotros' className="nosotros my-5">
      <SectionTitle title='NOSOTROS'/>
      {windowWidth >= 1201 ? (
        <Row className="us-section">
          <Col xs={12} md={12} xl={4} className="center-image">
            <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} src="img/HOMEPAGE/IMAGEN-NOSOTROS.webp" alt="NOSOTROS" />
          </Col>
          <Col xs={10} md={10} xl={8} className="text-nosotros">
            {text_nosotros}
          </Col>
        </Row>
      ) : (
        <>
          <Row className="us-section">
            <Col xs={12} md={12} className="center-image">
              <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px', margin:'0 auto'}} src="img/HOMEPAGE/IMAGEN-NOSOTROS.webp" alt="NOSOTROS" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className="text-nosotros">
              {text_nosotros}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default AboutUsSection;
