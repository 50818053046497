import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { handleGoContactPage } from '../../utils/navigateToContact';
import CustomButton from '../../components/CustomButton';
import { useNavigate } from 'react-router-dom';

const ImageBottomSection = () => {
  const navigate = useNavigate();
  return (
    <Container className='full-bottom-image'>
      <Row className="justify-content-flex-start text-center bg-bottom">
        <Col xl={12} className="text-main">
          SUEÑA - PROYECTA - HABITA
        </Col>
        <Col xl={12} className="text-main-lg">
          UNA NUEVA ARQUITECTURA PUEDE SER POSIBLE
          <CustomButton className="btn-start" size='lg' onclick={handleGoContactPage('', navigate)}>
            EMPECEMOS
          </CustomButton>
        </Col>
        <Col>
        </Col>
      </Row>
    </Container>
  );
};

export default ImageBottomSection;
