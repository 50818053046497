import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useNavigateProjects } from '../../utils/navigateProjects';
import { handleGoContactPage } from '../../utils/navigateToContact';
import './ProjectFooter.css';
import { useDynamicRoutesContext } from '../../utils/dynamicRoutesContext'


const ProjectFooter =  ({rowClassName, projectName}) => {
    const storedRoutes = useDynamicRoutesContext();

    const {handleProjectsPrevRouteClick, handleProjectsNextRouteClick} = useNavigateProjects();
    
    return (
        <div>
            <Container>
                <Row className={rowClassName}>
                    <Col xs={2} md={2} xl={1} className='button-col'>
                        <Button className="left-button-bottom" onClick={(e) => handleProjectsPrevRouteClick(e, storedRoutes)}></Button>
                    </Col>
                    <Col xs={8} md={8} xl={10} className='button-col'>
                        <Button className="start-button-bottom" onClick={(e) => handleGoContactPage(`¡Hola! Estoy interesado en saber más sobre el proyecto ${projectName}.`)}>¡Comencemos!</Button>
                    </Col>
                    <Col xs={2} md={2} xl={1} className='button-col'>
                        <Button className="right-button-bottom" onClick={(e) => handleProjectsNextRouteClick(e, storedRoutes)}></Button>
                    </Col>
                </Row>
            </Container>
            <Col className="bottom-image-generativa"></Col>
        </div>
    );
};

export default ProjectFooter;


