import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CustomButton from '../../components/CustomButton';    
import { handleGoContactPage } from '../../utils/navigateToContact';
import './CustomHyperLinksSection.css';

const handleSelect = (eventKey) => {
    const anchor = document.querySelector(eventKey);
    if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
        console.error(`El elemento no se encuentra en el DOM: ${eventKey}`);
    }
};

const ItemCustomLink = ({item_name, hash_location}) => {
    return (
        <Nav.Link className='navbar-title' href={`#${hash_location}`}> 
            <LazyLoadImage style={{height:'auto'}}
                src="img/SERVICIOS/0s_0003_FLECHA-1.webp" 
                alt="left-icon" 
                className='icon-left' />
            <span>{item_name}</span>
        </Nav.Link>
    )
}

const CustomHyperLinksSection = () => {
    return (
        <Container>
            <Row style={{marginBottom:'150px'}}>
                <Col xs={12} md={12} xl={8}>
                    <Nav className="flex-column service-list" onSelect={handleSelect}>
                        <ItemCustomLink item_name="CARTERA DE SERVICIOS" hash_location='carteraServicios'/>
                        <ItemCustomLink item_name="ASESORÍAS" hash_location='asesorias'/>
                        <ItemCustomLink item_name="MASTER PLAN" hash_location='masterPlan'/>
                        <ItemCustomLink item_name="PLANES COMERCIO Y EMPRESAS" hash_location='planesComercio' />
                    </Nav>  
                </Col>

                <Col xs={12} md={12} xl={4} className='button-col-center'>
                    <CustomButton className='btn-start-services' onclick={handleGoContactPage('')}>
                        ¡Comencemos!
                    </CustomButton>
                </Col>
            </Row>
        </Container>
    )
}

export default CustomHyperLinksSection;