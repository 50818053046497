import React, {useState} from "react";
import ItemSection from "./ItemSection";
import EditProjectModal from "../EditModal/EditProjectModal";
import DeleteModal from "../DeleteModal/DeleteModal";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./ProjectSection.css";

function ProjectSection({ }) {
  
  const [currentItem, setCurrentItem] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const buttonBodyEdit = (color) => <svg className="button-icon" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill={color} d="M3 21v-4.25L16.2 3.575q.3-.275.663-.425t.762-.15t.775.15t.65.45L20.425 5q.3.275.438.65T21 6.4q0 .4-.137.763t-.438.662L7.25 21zM17.6 7.8L19 6.4L17.6 5l-1.4 1.4z"/></svg>;
  const buttonBodyDelete = (color) => <svg className="button-icon" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill={color} d="m9.4 16.5l2.6-2.6l2.6 2.6l1.4-1.4l-2.6-2.6L16 9.9l-1.4-1.4l-2.6 2.6l-2.6-2.6L8 9.9l2.6 2.6L8 15.1zM7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21z"/></svg>;
  
  
  const handleCloseEditModal = () => setShowEditModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  
  const handleEdit = (item) => {
    setCurrentItem(item);
    setShowEditModal(true);
  };

  const handleDelete = (item) => {
    setCurrentItem(item);
    setShowDeleteModal(true);
  };

  const renderProjectCardContent = (project) => (
    <>
      <Row>
        <Card.Title className="project-name">{project.name}</Card.Title>
        <Col xs={4} style={{alignSelf:'center'}}>
          <Card.Img className="card-image" src={project.main_image} />
        </Col>
        <Col xs={8} style={{alignSelf:'flex-end'}}>
          <Card.Body>
            <Card.Text style={{textAlign:'left', color:'#fafafa'}}>
              <strong>Superficie:</strong> {project.surface} m<sup>2</sup>
              <br />
              <strong>Ubicación:</strong> {project.location}
            </Card.Text>
            <Card.Text style={{textAlign:'left', fontFamily:'Cedora-Regular', fontSize:'1.3rem', color:'#fafafa'}}>
              {project.description1}
            </Card.Text>
            <Card.Text style={{textAlign:'left', fontFamily:'Cedora-Regular', fontSize:'1.3rem', color:'#fafafa'}}>
              {project.description2}
            </Card.Text>
          </Card.Body>
        </Col>
        <Col xs={12}>
          <Button className='button-body' onClick={() => handleEdit(project)}>
            {buttonBodyEdit("#6e312a")}
          </Button>
          <Button className='button-body' onClick={() => handleDelete(project)}>
            {buttonBodyDelete("#6e312a")}
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <ItemSection
        title="Proyectos"
        fetchUrl="api/admin/projects/getAll"
        renderCardContent={renderProjectCardContent}
        currentItem={currentItem}
        showEditModal={showEditModal}
        showDeleteModal={showDeleteModal}
        EditModal={EditProjectModal}
        DeleteModal={DeleteModal}
        handleCloseEditModal={handleCloseEditModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        classContainer="projects-section"
        classCard="project-card"
      />

    </>
  );
}

export default ProjectSection;