import React from 'react';
import Col from 'react-bootstrap/Col';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function LayoutSelection({ layouts, selectedLayout, handleLayoutSelection }) {
    return (
        <div className="layout-selection">
            {layouts.map((layout, index) => (
                <Col key={index} className={`layout-item ${selectedLayout === layout.name ? 'selected' : ''}`} onClick={() => handleLayoutSelection(layout.name)}>
                    <span style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>{layout.name}</span>
                    <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} className='layout-image' src={layout.image} alt={layout.name} />
                </Col>
            ))}
        </div>
    );
}

export default LayoutSelection;