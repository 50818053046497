// routeService.js
export const fetchDynamicRoutes = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/api/dynamic-routes`);

    if (!response.ok) {
        throw new Error('Error fetching dynamic routes');
    }

    return response.json();
};