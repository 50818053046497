import React from 'react';
import Container from 'react-bootstrap/Container';
import CustomCarousel from '../../components/CustomCarousel/CustomCarousel';
import SectionTitle from './SectionTitle';

const CarouselSection = ({ groupedProjects, containerClassname, title, cardClass, cardParent, carouselType }) => (
    <Container className={`${containerClassname} my-5`}>
        <SectionTitle title={title} />
        <CustomCarousel groupedItems={groupedProjects} cardClass={cardClass} cardParent={cardParent} carouselType={carouselType} />
    </Container>
);
export default CarouselSection;
