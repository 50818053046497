import { useMemo } from 'react';
import { useDynamicRoutesContext } from './dynamicRoutesContext'; // Importa el hook del contexto

export const useProjects = (projectsNames) => {
    const dynamicRoutes = useDynamicRoutesContext(); // Obtén las rutas desde el contexto

    const projects = useMemo(() => {
        return dynamicRoutes
            .filter(route => route.path.startsWith('/proyectos/') && route.path !== '/proyectos')
            .map((route, index) => ({
                route: route.path,
                src: `img/HOMEPAGE/${route.name.toUpperCase().replace(/\s+/g, '-')}.webp`,
                name: route.name,
            }));
    }, [projectsNames, dynamicRoutes]);

    return { projects, isLoading: false };
};