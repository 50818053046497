import React from 'react';
import Col from 'react-bootstrap/Col';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Button from 'react-bootstrap/Button';

const removeIcon = (color, onClick) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" onClick={onClick}>
        <path fill={color} d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6zm3.6 5q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"/>
    </svg>
);

const ImagePreview = ({ image, index, removeImage, className, imageWidth, color='#fafafa' }) => {
    return (
    <Col xs={ imageWidth || 3} className={className} key={index} style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
        <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} src={image.preview} alt="" />
        <Button onClick={(event) => {
            event.stopPropagation(); 
            removeImage(index);
        }} className='delete-preview-image-button'>
            {removeIcon(color)}
        </Button>
    </Col>
    );
};

export default ImagePreview;