import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { fetchProjects } from '../../../../services/projectService';
import { fetchServices } from '../../../../services/serviceService';
import Loader from '../../../../components/Loader';
import './ProjectSection.css';  

function ItemSection({ title, fetchUrl, renderCardContent, EditModal, DeleteModal, classContainer, classCard, currentItem, showEditModal, showDeleteModal, handleCloseEditModal, handleCloseDeleteModal, handleImagesChange }) {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchItems = async () => {
        try {
            let data;
            setLoading(true);
            if (fetchUrl.includes('projects')) {
                data = await fetchProjects();
            } else if (fetchUrl.includes('services')) {
                data = await fetchServices();
            }

            if (data) {
                setItems(data);
            } else {
                console.error('No data fetched');
            }
        } catch (error) {
            console.error(`Error fetching ${title.toLowerCase()}:`, error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchItems();
    }, [fetchUrl]);

    
    const renderItemsSection = () => {
        return (
            items.map((item) => (
                <Col xs={12} key={item.id}>
                    <Card className={classCard}>
                        {renderCardContent(item)}
                    </Card>
                </Col>
            ))
        );
    }

    return (
        <Container 
            className={classContainer} 
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <Row style={{ width: '100%', alignItems: 'center' }}>
                <Col xs={12}>
                    <h1 className='title'>{title}</h1>
                </Col>
            </Row>
            <Row>
                {loading? <Loader/>:renderItemsSection()}
            </Row>
            <EditModal 
                show={showEditModal} 
                handleClose={handleCloseEditModal} 
                project={currentItem}
            />
            <DeleteModal 
                show={showDeleteModal} 
                handleClose={handleCloseDeleteModal} 
                project={currentItem} 
                onProjectDeleted={fetchItems}
            />
        </Container>
    );
}

export default ItemSection;