export const fetchProjectById = async (id) => {
    const token = localStorage.getItem('token');  // Obtener el token directamente

    if (!token) {
        throw new Error('Token no disponible');
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/projects/getById/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });

    if (!response.ok) {
        throw new Error('Error fetching project data');
    }

    return response.json();
};
  
export const fetchProjects = async () => {
    const cachedProjects = localStorage.getItem('projects');

    if (cachedProjects) {
        return JSON.parse(cachedProjects);
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/projects/getAll`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
  
    if (!response.ok) {
        throw new Error('Error fetching projects');
    }
  
    const data = await response.json();
    localStorage.setItem('projects', JSON.stringify(data)); // Cache the projects in localStorage
    return data;
};