import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Layout1 = ({ images, className, rowClassName }) => (
    <Container>
        <Row className={rowClassName}>
            {images.map((image, index) => {
                const colSize = image.size || 4;
                return (
                    <Col
                        xs={12}
                        md={colSize}
                        key={`image-${index}`}
                        style={{ textAlign: 'center', marginBottom: '25px' }}
                    >
                        <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} src={image.src} alt={image.alt} className={className} />
                    </Col>
                );
            })}
        </Row>
    </Container>
);

const Layout2 = ({ images, classNameLeftSide, classNameRightSide }) => (
    <Container>
        <Row>
            <Col xs={12} md={images[0].size || 6} style={{ marginBottom: '25px' }}>
                <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} src={images[0].src} alt={images[0].alt} className={classNameLeftSide} />
            </Col>
            <Col xs={12} md={images[1].size || 6}>
                <Row>
                    <Col xs={12} md={12} style={{ marginBottom: '25px' }}>
                        <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} src={images[1].src} alt={images[1].alt} className={classNameRightSide} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} style={{ marginBottom: '25px' }}>
                        <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} src={images[2].src} alt={images[2].alt} className={classNameRightSide} />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
);


const Layout3 = ({ images, className, rowClassName }) => (
    <Container>
        <Row className={rowClassName}>
            {images.map((image, index) => (
                <Col xs={12} md={image.size || 6} key={`image-${index}`} style={{ marginBottom: '25px', display:'flex', justifyContent:`${index%2===1? 'flex-start':'flex-end'}` }}>
                    <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} src={image.src} alt={image.alt} className={className} />
                </Col>
            ))}
        </Row>
    </Container>
);

const Layout4 = ({ images, className, rowClassName }) => (
    <Container>
        <Row className={rowClassName}>
            {images.map((image, index) => (
                <Col xs={12} md={image.size || 3} key={`image-${index}`} style={{ marginBottom: '25px' }}>
                    <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} src={image.src} alt={image.alt} className={className} />
                </Col>
            ))}
        </Row>
    </Container>
);

const ProjectImages = ({ className, imagesLayouts, rowClassName, classNameLeftSide, classNameRightSide }) => (
    <>
        {imagesLayouts.map((imagesLayout, index) => {
            let { images, layout } = imagesLayout;
            images = JSON.parse(images);
            switch(layout) {
                case 1:
                    return <Layout1 
                                key={`layout-${index}`} 
                                images={images} 
                                className={className} 
                                rowClassName={rowClassName}
                            />;
                case 2:
                    return <Layout2 
                                key={`layout-${index}`} 
                                images={images} 
                                className={className} 
                                classNameLeftSide={classNameLeftSide}
                                classNameRightSide={classNameRightSide}
                            />;
                    case 3:
                    return <Layout3 
                                key={`layout-${index}`} 
                                images={images} 
                                className={className} 
                                rowClassName={rowClassName}
                            />;
                case 4:
                    return <Layout4 
                                key={`layout-${index}`} 
                                images={images} 
                                className={className} 
                                rowClassName={rowClassName}
                            />;
                default:
                    return null;
            }
        })}
    </>
);

export default ProjectImages;