import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CardComponent } from './CardComponent';
import './CustomCarousel.css';

function CustomCarousel({ groupedItems, cardClass, cardParent, carouselType }) {
    return (
        <Carousel interval={null} className={carouselType}>
            {groupedItems.map((group, index) => (
                <Carousel.Item key={index}>
                    <Row className='landing-display'>
                        {group.map((item, subIndex) => (
                            <Col className={cardParent} xs={12} md={6} xl={4} key={subIndex} >
                                <CardComponent route={item.route} src={item.src} name={item.name} cardClass={cardClass} />
                            </Col>
                        ))}
                    </Row>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default CustomCarousel;
