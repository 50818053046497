import React from 'react';
import './CardAsesoria.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


const CardAsesoria = ({title, description, duration, report, mode }) => {
    return (
        <Container fluid className="card-asesoria">
            <Row>
            <Col xs={4} className="card-asesoria-title-section">
                <h1 className='title-card'>{title}</h1>
            </Col>
            <Col xs={9} className="card-asesoria-description-section">
                <Row>
                    <h2 className='description-card'>{description}</h2>
                    <Col className="card-asesoria-details">
                        <span>{duration}</span>
                        <span>{report}</span>
                        <span>{mode}</span>
                    </Col>
                </Row>
            </Col>
            </Row>
        </Container>
    );
};

export default CardAsesoria;