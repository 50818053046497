import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ImageSelection from './ImageSelection';
import LayoutSelection from './LayoutSelection';
import SavedGroups from './SavedGroups';
import { ErrorModal, ConfirmModal, SuccessModal } from './Modals';
import { createProject, createProjectLayouts, createProjectView } from './projectCreator';
import { uploadProjectImages } from '../imageHandler';
import { runBuildAndDeploy } from './runAndDeploy';
import './SelectImagesLayout.css';

function SelectImagesLayout({ images = [], setActiveKey, onImagesChange, projectData }) {
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedLayout, setSelectedLayout] = useState('Layout 1');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [savedGroups, setSavedGroups] = useState([]);

    const layouts = [
        { name: 'Layout 1', image: '/img/LAYOUTS/LAYOUT1.webp', maxImages: 3, default_images_size: 4 },
        { name: 'Layout 2', image: '/img/LAYOUTS/LAYOUT2.webp', maxImages: 3, default_images_size: 6 },
        { name: 'Layout 3', image: '/img/LAYOUTS/LAYOUT3.webp', maxImages: 2, default_images_size: 6 },
        { name: 'Layout 4', image: '/img/LAYOUTS/LAYOUT4.webp', maxImages: 4, default_images_size: 3 },
    ];

    const initialLayout = layouts.find(layout => layout.name === selectedLayout);
    const [imageSize, setImageSize] = useState(initialLayout.default_images_size);

    let selectedOrder = [];

    const handleSuccessModalClose = () => {
        setShowSuccessModal(false);
        setActiveKey('editProjects');
        runBuildAndDeploy();
    };
    // Maneja la selección de imágenes
    const toggleImageSelection = (image) => {
        const maxImages = layouts.find(layout => layout.name === selectedLayout).maxImages;
        if (selectedImages.length <= maxImages) {
            if (selectedImages.includes(image)) {
                setSelectedImages(selectedImages.filter(img => img !== image));
                selectedOrder = selectedOrder.filter(img => img !== image);
            } else {
                setSelectedImages([...selectedImages, image]);
                selectedOrder.push(image);
            }
        } else {
            setSelectedImages(selectedImages.slice(0, selectedImages.length - 1));
            setErrorMessage(`El layout ${selectedLayout} solo permite ${maxImages} imágenes.`);
            setShowErrorModal(true);
        }
    };

    // Maneja la selección de layouts
    const handleLayoutSelection = (layout) => {
        setSelectedLayout(layout);
        const selected = layouts.find(l => l.name === layout);
        setImageSize(selected.default_images_size);
    };

    // Maneja el cambio de tamaño de imágenes
    const handleImagesSizeChange = (newSize, groupIndex, imageIndex) => {
        const updatedGroups = [...savedGroups];
        updatedGroups[groupIndex].images[imageIndex].size = parseInt(newSize);
        setSavedGroups(updatedGroups);
    };

    // Elimina un grupo de imágenes guardado
    const removeGroup = (groupIndex) => {
        const group = savedGroups[groupIndex];
        const updatedGroups = savedGroups.filter((_, i) => i !== groupIndex);
        setSavedGroups(updatedGroups);
        const updatedImages = images.concat(group.images.map(img => ({
            src: img.src,
            preview: img.preview, 
            name: img.alt,
            size: img.size
        })));
        onImagesChange(updatedImages);
    };

    // Elimina una imagen seleccionada
    const removeImage = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        onImagesChange(updatedImages);
    };

    // Maneja la selección y guardado de imágenes
    const handleSubmitSelection = () => {
        const projectNameForPath = projectData.name.toUpperCase().replace(/ /g, '_');
        if (selectedImages.length > 0) {
            const newGroup = {
                layout: parseInt(selectedLayout.split(' ')[1]),
                images: selectedImages.map((image) => ({
                    src: `/img/${projectNameForPath}/${image.name}`,
                    preview: image.preview || image.src,
                    alt: image.name,
                    size: imageSize <= 12 ? imageSize : image.size,
                }))
            };
            setSavedGroups([...savedGroups, newGroup]);
            setSelectedImages([]);
            onImagesChange(images.filter(image => !selectedImages.includes(image)));
        }
    };

    // Maneja el envío final de las imágenes y layouts seleccionados
    const handleSubmit = (event) => {
        event.preventDefault();
        if (savedGroups.length > 0) {
            setShowConfirmModal(true);
        } else {
            alert('Debes seleccionar al menos un grupo de imágenes');
        }
    };

    // Maneja el retorno al paso anterior
    const handleBack = (event) => {
        event.preventDefault();
        setActiveKey('createProject');
        localStorage.setItem('activeTab', 'createProject');
    };

    const handleConfirm = async (event) => {
        event.preventDefault();
        console.log('Confirming project creation');
        try {
            const project = await createProject(projectData);
            
            await createProjectLayouts(project.project.id, savedGroups, projectData);

            await uploadProjectImages(projectData, savedGroups);
    
            await createProjectView(projectData.name);
    
            setShowConfirmModal(false);
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage(error.message || 'Error creando el proyecto');
            setShowErrorModal(true);
        }
    };

    const returnButton = (color) => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/>
                <path fill={color} d="M3.076 5.617A1 1 0 0 1 4 5h10a7 7 0 1 1 0 14H5a1 1 0 1 1 0-2h9a5 5 0 1 0 0-10H6.414l1.793 1.793a1 1 0 0 1-1.414 1.414l-3.5-3.5a1 1 0 0 1-.217-1.09"/>
            </g>
        </svg>
    );

    return (
        <>
            <div className="select-images-layout">
                <Row>
                    <Button className='return-button' onClick={handleBack}>
                        {returnButton('#fafafa')}
                    </Button>
                </Row>
                <Row className="image-selection">
                    <Col xs={6} className="images">
                        <h5 style={{ width: '100%', textAlign: 'center', padding: '1rem', color: 'white' }}>Imágenes</h5>
                        <ImageSelection
                            images={images}
                            selectedImages={selectedImages}
                            layouts={layouts}
                            selectedLayout={selectedLayout}
                            toggleImageSelection={toggleImageSelection}
                            removeImage={removeImage}
                        />
                    </Col>
                    <Col xs={4} className="layouts">
                        <h5 style={{ width: '100%', textAlign: 'center', padding: '1rem', color: '#6e132a' }}>Layouts</h5>
                        <LayoutSelection
                            layouts={layouts}
                            selectedLayout={selectedLayout}
                            handleLayoutSelection={handleLayoutSelection}
                        />
                    </Col>
                </Row>
                <SavedGroups
                    savedGroups={savedGroups}
                    handleImagesSizeChange={handleImagesSizeChange}
                    removeGroup={removeGroup}
                    handleSubmitSelection={handleSubmitSelection}
                    images={images}
                />
                <Row style={{ justifyContent: 'space-evenly', margin: '20px 0' }}>
                    <Button className='return-button' onClick={handleBack}>
                        {returnButton('#fafafa')}
                    </Button>
                    <Button className='submit-button' onClick={handleSubmit}>
                        Aceptar
                    </Button>
                </Row>
            </div>
            <ErrorModal show={showErrorModal} handleHide={() => setShowErrorModal(false)} errorMessage={errorMessage} />
            <ConfirmModal show={showConfirmModal} handleHide={() => setShowConfirmModal(false)} handleConfirm={handleConfirm} />
            <SuccessModal show={showSuccessModal} handleHide={handleSuccessModalClose} />
        </>
    );
}

export default SelectImagesLayout;