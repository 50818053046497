import {React, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { useServicesData } from '../../utils/servicesData';
import { useProjects } from '../../utils/projects';
import { chunkArray } from '../../utils/utilsArrays';
import { useProjectEffects } from '../../utils/useProjectEffects';
import CustomNavbar from '../../components/CustomNavbar/CustomNavbar';
import CustomButton from '../../components/CustomButton';
import InfoSection from './InfoSection';
import CarouselSection from './CarouselSection';
import AboutUsSection from './AboutUsSection';
import ImageBottomSection from './ImageBottomSection';
import ButtonBottomSection from './ButtonBottomSection';
import Loader from '../../components/Loader';
import './HomePage.css';


const HomePage = () => {

    const {projects, isLoading} = useProjects();
    const servicesData = useServicesData();
    const upperText1 = "Tu vida se transforma";
    const upperText2 = "la Arquitectura se adapta a ti";
    const lowerText = "Diseñamos lugares especialmente adaptados a la forma de tu vida, tenemos un enfoque de diseño que busca la armonía y la integración en todos los aspectos de la arquitectura, buscando un resultado final que sea estéticamente atractivo, funcional y sostenible.";
    const text_nosotros = 'En Estudio Regular, no somos simplemente arquitectos. Nos enorgullece ir más allá de las formas y centrarnos en tu estilo de vida. No creemos en espacios estáticos que limiten tus posibilidades. Nuestro enfoque es versatilizar y ampliar las posibilidades de apropiación en cada proyecto, porque creemos que tu hogar debe adaptarse a ti, no al revés. No diseñamos solo casas, creamos lugares que encajan perfectamente con tu vida. Únete a nosotros para dar vida a tu visión arquitectónica y hacer que tu espacio refleje verdaderamente quién eres.';
    const [expanded, setExpanded] = useState(false)
    const windowWidth = useProjectEffects(expanded);
    
    const navigate = useNavigate();

    const handleGoContactPage = () => {
        navigate('/contacto');
    };

    const calculateItemsPerGroup = (width) => {
        if (width > 1201) {
            return 3;
        } else if (width > 768) {
            return 2;
        } else {
            return 1;
        }
    };
    
    const [currentItemsPerGroup, setCurrentItemsPerGroup] = useState(calculateItemsPerGroup(windowWidth));

    const [groupedProjects, setGroupedProjects] = useState(chunkArray(projects, currentItemsPerGroup));
    const [groupedServices, setGroupedServices] = useState(chunkArray(servicesData, currentItemsPerGroup));
    
    const location = useLocation();

    useEffect(() => {
        const initialItemsPerGroup = calculateItemsPerGroup(windowWidth);
        
        const newGroupedProjects = chunkArray(projects, initialItemsPerGroup);
        const newGroupedServices = chunkArray(servicesData, initialItemsPerGroup);
    
        if (JSON.stringify(newGroupedProjects) !== JSON.stringify(groupedProjects)) {
            setGroupedProjects(newGroupedProjects);
        }
    
        if (JSON.stringify(newGroupedServices) !== JSON.stringify(groupedServices)) {
            setGroupedServices(newGroupedServices);
        }
    
        setCurrentItemsPerGroup(initialItemsPerGroup);
    }, [projects, servicesData, windowWidth]);

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const name = hash.substring(1);
            const element = document.getElementById(name);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center'});
            }
        }
    }, [location]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <CustomNavbar />
            <Container fluid className="main-image homepage text-center bg-light">
                <h1 className="init-text">Arquitectura <br />en Equilibrio</h1>
                <CustomButton variant="primary" size="lg" className="btn-start-big btn-start-big-custom" onclick={handleGoContactPage}>
                    ¡Comencemos!
                </CustomButton>
            </Container>

            <InfoSection upperText1={upperText1} upperText2={upperText2} lowerText={lowerText} windowWidth={windowWidth}>
                <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} src="img/HOMEPAGE/0_Capa-2.webp" alt="Interior de la casa" className='fix-height' />
            </InfoSection>
            
            <CarouselSection groupedProjects={groupedProjects} containerClassname='proyectos' title={'PROYECTOS'} cardParent='projects-carousel' cardClass='project-card' carouselType='projects'/>

            <CarouselSection groupedProjects={groupedServices} containerClassname='servicios' title={'SERVICIOS'} cardParent='services-carousel' cardClass='service-card' carouselType='services'/>

            <AboutUsSection windowWidth={windowWidth} text_nosotros={text_nosotros}/>

            <ImageBottomSection/>

            <Container fluid>
                <Row>
                    <ButtonBottomSection button_name='EMPECEMOS' onclick={handleGoContactPage}>
                        ¿ERES EMPRESA? <br />ELIGE NUESTROS PLANES
                    </ButtonBottomSection>
                    <ButtonBottomSection button_name='HABLEMOS' onclick={handleGoContactPage}>
                        ES NORMAL TENER PREGUNTAS
                    </ButtonBottomSection>
                </Row>
            </Container>
    </div>
    );
}


export default HomePage;
