import React from 'react';
import './Loader.css';

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
            <p style={{color:'#6e132a', textAlign:'center', fontSize:'2rem'}}>Cargando...</p>
        </div>
    );
};

export default Loader;