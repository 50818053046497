import React from 'react';
import Container from 'react-bootstrap/Container';
import CustomNavbar from '../CustomNavbar/CustomNavbar';
import Row from 'react-bootstrap/Row';

const ProjectHeader = ({ className, title, children, backgroundImage }) => {
    const headerStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        maxWidth: '100%',
        aspectRatio: '16/9',
    };

    return (
        <Row className={className}>
            <CustomNavbar />
            <Container fluid className={`main-image text-left bg-light`} style={headerStyle}>
                <h1 className={"main-text-init"}>{children ? children : title}</h1>
            </Container>
        </Row>
    );
};

export default ProjectHeader;