import { lazy, useEffect, useState } from 'react';
import { fetchDynamicRoutes } from '../services/routeService';

export const dynamicImport = (folder, component) => {
    return lazy(() =>
        import(`../screens/${folder}/${component}`).then(module => {
            return { default: module.default || module };
        }).catch(error => {
            console.error(`Error importing component ${folder}/${component}:`, error);
            throw error;
        })
    );
};

export const useDynamicRoutes = () => {
    const [dynamicRoutes, setDynamicRoutes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const fetchRoutes = async () => {
            try {
                const routesData = await fetchDynamicRoutes();
                const routesArray = routesData.map(route => {
                    const Component = dynamicImport(route.folder, route.component);
                    return {
                        path: route.path,
                        component: route.component,
                        name: route.name,
                        element: <Component />
                    };
                });
                setDynamicRoutes(routesArray);
            } catch (error) {
                console.error('Error fetching dynamic routes:', error);
            } finally {
                setIsLoading(false);
            }
        };
        
        fetchRoutes();
    }, []);

    return { dynamicRoutes, isLoading };
};