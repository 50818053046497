import React from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

const CustomButton = ({ variant, size, className, route, onclick, children }) => {
    const navigate = useNavigate();

    const handleNavClick = (event) => {
        const isModifierPressed = event.button === 1 || event.ctrlKey || event.metaKey || event.shiftKey;

        if (!isModifierPressed) {
            event.preventDefault();
            if (route) navigate(route);
        }
    };

    return (
        <Button variant={variant} size={size} className={className} onClick={onclick || handleNavClick}>
            {children}
        </Button>
    );
};

export default CustomButton;
