// projectCreator.js

export const createProject = async (projectData) => {
    const formattedProjectName = projectData.name.toUpperCase().replace(/ /g, '-');
    const projectNameForPath = projectData.name.toUpperCase().replace(/ /g, '_');

    const header_image = `/img/${projectNameForPath}/IMAGEN-GRANDE.webp`;
    const main_image = `/img/${projectNameForPath}/1_${formattedProjectName}.webp`;
    const data = {
        name: projectData.name,
        location: projectData.location,
        surface: projectData.surface,
        header_image,
        main_image,
        description1: projectData.description1,
        description2: projectData.description2,
    };

    console.log('Creating project...', data);

    const createResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/projects/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(data)
    });

    if (!createResponse.ok) {
        const errorText = await createResponse.text();
        throw new Error(`Error creating project: ${errorText}`);
    }
    const responseData = await createResponse.json();
    return responseData;
};

export const createProjectLayouts = async (projectId, savedGroups, projectData) => {
    let imageCounter = 2;

    const createLayoutPromises = savedGroups.map(async (group) => {
        const layoutData = {
            project_id: projectId,
            layout_type: group.layout,
            image_group: group.images.map((image) => {
                const imageName = `/img/${projectData.name.toUpperCase().replace(/ /g, '_')}/${imageCounter}_${projectData.name.toUpperCase().replace(/ /g, '-')}.webp`;
                imageCounter++;
                return {
                    src: imageName,
                    alt: imageName,
                    size: image.size
                };
            })
        };

        const layoutResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/project-layouts/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                ...layoutData,
                image_group: JSON.stringify(layoutData.image_group)
            })
        });

        if (!layoutResponse.ok) {
            const errorText = await layoutResponse.text();
            throw new Error(`Error creating layout: ${errorText}`);
        }

        return await layoutResponse.json();
    });

    await Promise.all(createLayoutPromises);
};

export const createProjectView = async (projectName) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/projects/create-view`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({ projectName })
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error creating project view: ${errorText}`);
    }
    
    console.log('Project view created successfully');
};