import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CustomButton from '../../components/CustomButton';
import { setScreenWidthVariable } from '../../utils/setScreenWidthVariable';
import Spinner from 'react-bootstrap/Spinner'; // Importa el componente de Spinner
import './ContactPage.css';
import CustomNavbar from '../../components/CustomNavbar/CustomNavbar';
import renderSocialMediaLogo from '../../components/RenderSocialMediaLogos';

const ContactPage = () => {
    
    const [expanded, setExpanded] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('El Mensaje se ha enviado con éxito!');
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showModalLoading, setShowModalLoading] = useState(false);

    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const sanitizeHtml = require('sanitize-html');


    // Función para validar el email
    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    // Función para validar un número de teléfono (ajústalo a tus necesidades)
    const validatePhone = (phone) => {
        const re = /^\+?(\d{10,15})$/; // Regex para validar números de teléfono internacionales
        return re.test(String(phone));
    };

    // Función para validar que el texto no esté vacío
    const validateNotEmpty = (text) => {
        return text.trim().length > 0;
    };

    const validateLettersOnly = (text) => {
        // Esta expresión regular incluye letras, espacios, y la letra ñ (tanto mayúscula como minúscula).
        return /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ\s\n]+$/.test(text);
    };
    
    const validateLettersNumbersAndSomePunctuation = (text) => {
        // Esta expresión regular incluye letras, números, espacios, ciertos signos de puntuación, la letra ñ y saltos de línea.
        return /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚüÜ\s.,;\n]+$/.test(text);
    };

    const validateText = (text) => {
        // Sanitizar el texto para eliminar HTML y scripts potencialmente peligrosos
        return sanitizeHtml(text, {
            allowedTags: [],
            allowedAttributes: {},
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault(); // Previene el comportamiento predeterminado del formulario
        setShowModalLoading(true);
        if (!validateEmail(email) || !validateNotEmpty(email) || !validateText(email)) {
            setErrorModalMessage('Por favor ingresa un correo electrónico válido.');
            return;
        }
        
        if (!validateNotEmpty(message) || !validateLettersNumbersAndSomePunctuation(message) || !validateText(message)) {
            setErrorModalMessage('Por favor ingresa un mensaje. Solo se permiten letras, números y puntuación básica.');
            return;

        }
        
        if (!validateNotEmpty(name) || !validateLettersOnly(name) || !validateText(name)) {
            setErrorModalMessage('Por favor ingresa tu nombre. Solo se permiten letras.');
            return;
        }
    
        
        if (!validateNotEmpty(lastName) || !validateLettersOnly(lastName) || !validateText(lastName)) {
            setErrorModalMessage('Por favor ingresa tu apellido. Solo se permiten letras.');
            return;
        }

        if (!validatePhone(phone)) {
            setErrorModalMessage('Por favor ingresa un número de teléfono válido.');
            return;
        }
        setShowModalLoading(true);
        // Configura los datos del formulario para enviar
        // Sanitizar las entradas antes de enviar.
        const sanitizedMessage = validateText(message);
        const sanitizedName = validateText(name);
        const sanitizedLastName = validateText(lastName);

        // Configura los datos del formulario para enviar
        const formData = { 
            name: sanitizedName, 
            lastName: sanitizedLastName, 
            email, // El email ya fue validado previamente
            phone, // Asume que la validación del teléfono es suficiente
            message: sanitizedMessage 
        };
        
        const sendEmailRequest = fetch(`${process.env.REACT_APP_API_URL}/api/send-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
        
        sendEmailRequest
            .then(response => {
                if (!response.ok) {
                    setErrorModalMessage('Hubo un error al enviar el mensaje.');
                    setShowErrorModal(true);
                    throw new Error('Error en la solicitud');
                }
                setShowModalLoading(false);
                return response.text();
            })
            .then(data => {
                console.log('Respuesta del servidor:', data);
                try {
                    const jsonData = JSON.parse(data);
                    // Si el parseo es exitoso, manejar la respuesta JSON aquí
                    setName('');
                    setLastName('');
                    setEmail('');
                    setPhone('');
                    setMessage('');
                    setShowModal(true);
                    setShowModalLoading(false);
                } catch (e) {
                    // Si el parseo falla, manejar el texto plano aquí
                    console.warn('La respuesta no es un JSON válido:', data);
                    setShowModal(true); // Mostrar el modal de éxito o ajustar según tu lógica
                }
            })
            .catch(error => {
                // Maneja cualquier error aquí
                setShowModalLoading(false);
                setErrorModalMessage('Hubo un error al enviar el mensaje.');
                setShowErrorModal(true);
            });
            setShowModalLoading(false);
    };

    const sendMessageToWhatsApp = (e) => {
        if (e === 2) window.open('https://wa.me/56990883069?text=Hola%2C%20estoy%20interesado%20en%20tus%20servicios.', '_blank');
        else if (e === 1) window.open('https://wa.me/56973233273?text=Hola%2C%20estoy%20interesado%20en%20tus%20servicios.', '_blank');
    };
      
    
    useEffect(() => {
        if (expanded) {
          // Desactivar scroll cuando el menú está expandido
          document.body.style.overflow = 'hidden';
        } else {
          // Re-activar scroll cuando el menú está cerrado
          document.body.style.overflow = 'auto';
        }
    }, [expanded]); // Dependencia del estado expanded
    
    useEffect(() => {
        setScreenWidthVariable();
    }, []);

    useEffect(() => {

        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        
        window.addEventListener('resize', handleResize);
        
        handleResize();
        return () => {window.removeEventListener('resize', handleResize);}
    }, []);

    return (
        <div>
            <CustomNavbar />
            <Container fluid className="main-image-contact">
            </Container>    
            <Container className="contact-section my-5">
                <Row className="form-section">
                    <Col xs={12} md={8} xl={12}>
                    <h1 className='title-contact'>CONTACTO</h1>
                    <Form>
                        <Row className='form-spacing'>
                        <Col className='label-container' md={6} xl={6} >
                            <Form.Group controlId="formBasicName" className="form-floating-label">
                                <Form.Control type="text" placeholder=" " value={name} required onChange={(e) => setName(e.target.value)}/>
                                <Form.Label>Nombre</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col className='label-container' md={6} xl={6}>
                            <Form.Group controlId="formBasicLastName" className="form-floating-label">
                                <Form.Control type="text" placeholder=" " value={lastName} required onChange={(e) => setLastName(e.target.value)}/>
                                <Form.Label>Apellido</Form.Label>
                            </Form.Group>
                        </Col>
                        </Row>

                        <Form.Group controlId="formBasicEmail" className="form-floating-label form-spacing label-container">
                            <Form.Control type="email" placeholder="ejemplo@correo.com" value={email} required onChange={(e) => setEmail(e.target.value)} />
                            <Form.Label>Correo electrónico</Form.Label>
                        </Form.Group>

                        <Form.Group controlId="formBasicPhone" className="form-floating-label form-spacing label-container">
                            <Form.Control type="tel" placeholder="+56912341234" value={phone} required onChange={(e) => setPhone(e.target.value)} />
                            <Form.Label>Número celular</Form.Label>
                        </Form.Group>

                        <Form.Group controlId="formBasicMessage" className="form-floating-message-label label-container">
                            <Form.Control className='form-control-message' as="textarea" required rows={4} placeholder=" " value={message} onChange={(e) => setMessage(e.target.value)} />
                            <Form.Label>Mensaje</Form.Label>
                        </Form.Group>
                        <Row className='d-flex justify-content-center w-100'>
                            <Button variant='secondary' className="button-submit" type="submit" onClick={handleFormSubmit}>
                                Enviar
                            </Button>
                        </Row>
                    </Form>
                    </Col>
                </Row>
                <Row className="contact-info">
                    <Col>
                    <p id="whatsapp-link" onClick={() => sendMessageToWhatsApp(2) }>+569 9088 3069</p>
                    <p id="whatsapp-link" onClick={() => sendMessageToWhatsApp(1) }>+569 7323 3273</p>
                    <p>contacto@estudioregular.cl</p>
                    <p>@estudioregular</p>
                    <p>Valdivia, Chile</p>
                    </Col>
                </Row>
            </Container>
            <Container className='bottom-contact'>
                <Row>
                    <Col xs={12} md={6} xl={12} className='follow-us-text' >
                        <h3 className='follow-us'>¡¡SÍGUENOS EN NUESTRAS REDES !!</h3>
                        {renderSocialMediaLogo()}
                    </Col>
                </Row>
            </Container>
            <Col className="bottom-image-generativa"></Col>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='title-modal'>Mensaje del Sistema</Modal.Title>
                </Modal.Header>
                <Modal.Body className='message'>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <CustomButton className='btn-img-close' onclick={() => setShowModal(false)}>
                        Cerrar
                    </CustomButton>
                </Modal.Footer>
            </Modal>
            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='title-modal'>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body className='message'>{errorModalMessage}</Modal.Body>
                <Modal.Footer>
                    <CustomButton className='btn-img-close' onclick={() => setShowErrorModal(false)}>
                        Cerrar
                    </CustomButton>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalLoading} onHide={() => setShowModalLoading(false)}>
                <Modal.Header>
                    <Modal.Title className='title-modal'>Enviando Mensaje</Modal.Title>
                </Modal.Header>
                <Modal.Body className='message'>
                    <div className="d-flex justify-content-center align-items-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                </Modal.Body>
            </Modal>
    </div>
    );
};

export default ContactPage;
