import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function AddServiceModal({ show, handleClose }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Servicio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Formulario para agregar servicio */}
                <Form>
                    {/* Campos del formulario */}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                <Button variant="primary" type="submit">Guardar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddServiceModal;
