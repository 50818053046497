// CardsAsesoriasSection.jsx
import React from 'react';
import Col from 'react-bootstrap/Col';
import CardAsesoria from './CardAsesoria';

const CardsAsesoriasSection = ({ asesorias }) => {
    return (
        <Col xs={12} xl={8}>
            {asesorias.map((asesoria, i) => (
                <CardAsesoria key={i} index={i} {...asesoria} />
            ))}
        </Col>
    );
};

export default CardsAsesoriasSection;