import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Button from 'react-bootstrap/Button';
import './ControlPanel.css';
import ProjectSection from './ItemsSection/ProjectSection';
import AuthorizeUsers from './AuthorizeUsers/AuthorizeUsers';
import ServiceSection from './ItemsSection/ServiceSection';
import CreateProject from './Create/CreateProject';
import SelectImagesLayout from './Create/SelectImagesLayout';
import { fetchProjectById } from '../../../services/projectService';

const ControlPanel = () => {
  const navigate = useNavigate();
  const initialTab = 'editProjects';
  const [activeKey, setActiveKey] = useState(initialTab);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [projectData, setProjectData] = useState({});

  useEffect(() => {
    const savedActiveKey = localStorage.getItem('activeTab') || 'editProjects';
    setActiveKey(savedActiveKey);

    const verifyAndFetch = async () => {
        try {
            await fetchProjectById(1);
        } catch (error) {
            console.error('Error verifying token:', error);
            localStorage.removeItem('token');
            navigate('/admin/login');
        }
    };

    verifyAndFetch();
  }, [navigate]);

  function handleLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('activeTab');
    navigate('/admin/login');
  }

  function handleImagesChange(images) {
    setImagePreviews(images);
  }

  const handleCreateProject = () => {
    setActiveKey('createProject');
    localStorage.setItem('activeTab', 'createProject');
  };

  const handleSelect = (k, data = {}) => {
    setActiveKey(k);
    localStorage.setItem('activeTab', k);
    
    if (k === 'selectImages') {
      setProjectData(prevData => ({ ...prevData, ...data }));
    }
  };
  
  const handleProjectDataChange = (data) => {
    setProjectData(data);
  };

  return (
    <Container fluid className='admin'>
      <Row>
        <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={handleSelect}>
          <Col xs={2}>
            <Nav variant="pills" className="sidebar">
              <Navbar.Brand className='logo-container'>
                <LazyLoadImage  src="../logo-3-er-1.webp" alt="Estudio Regular Logo" className="logo align-top"/>
              </Navbar.Brand>
              <Nav.Item>
                <Nav.Link className='category' eventKey="editProjects">Editar Proyectos</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='category' eventKey="second">Editar Servicios</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='category' eventKey="authorize">Autorizar Usuarios</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className='logout' onClick={handleLogout}>Cerrar Sesión</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col xs={10}>
            <Tab.Content>
              <Tab.Pane eventKey="editProjects">
                <Row className='button-container'>
                  <Button className='button-add' onClick={handleCreateProject}>
                    Crear Proyecto
                  </Button>
                </Row>
                <ProjectSection />
              </Tab.Pane>
              <Tab.Pane eventKey="createProject">
                <CreateProject setActiveKey={setActiveKey} onImagesChange={handleImagesChange} onProjectDataChange={handleProjectDataChange}/>
              </Tab.Pane>
              <Tab.Pane eventKey="selectImages">
                <SelectImagesLayout setActiveKey={setActiveKey} images={imagePreviews} projectData={projectData} onImagesChange={handleImagesChange}/>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <ServiceSection />
              </Tab.Pane>
              <Tab.Pane eventKey="authorize">
                <AuthorizeUsers />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </Container>
  );
}

export default ControlPanel;