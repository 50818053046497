import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageUploadButton from '../Create/ImageUploadButton';
import { downloadAndUploadImage, uploadImageWithOverwriteOption } from '../imageHandler';
import FormField from '../FormField';
import { fetchProjectById } from '../../../../services/projectService';
import Loader from '../../../../components/Loader';
import './EditModal.css';

function EditProjectModal({ show, handleClose, project }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const formRef = useRef(null);
    const [location, setLocation] = useState('');
    const [surface, setSurface] = useState('');
    const [description1, setDescription1] = useState('');
    const [description2, setDescription2] = useState('');
    const [headerImagePreview, setHeaderImagePreview] = useState(null);
    const [thumbnailImagePreview, setThumbnailImagePreview] = useState(null);
    const [mainImagePreview, setMainImagePreview] = useState(null);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [headerImageFile, setHeaderImageFile] = useState(null);
    const [thumbnailImageFile, setThumbnailImageFile] = useState(null);
    const [mainImageFile, setMainImageFile] = useState(null);
    const [editImageIndex, setEditImageIndex] = useState(null);
    const [changedImages, setChangedImages] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [openIndexes, setOpenIndexes] = useState([]);

    const handleImageEdit = (groupIndex, index) => {
        const imageIndex = `${groupIndex}-${index}`;
        setOpenIndexes(prev => 
            prev.includes(imageIndex) 
                ? prev.filter(item => item !== imageIndex) 
                : [...prev, imageIndex]
        );
    };

    const extractFileName = (name, projectName) => {
        const projectPath = `/img/${projectName}/`;
        return name.replace(projectPath, '');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const projectData = await fetchProjectById(project.id);
    
                // Establecer los valores obtenidos para projectData
                setLocation(projectData.location);
                setSurface(projectData.surface);
                setDescription1(projectData.description1);
                setDescription2(projectData.description2);
    
                // Establecer previews de las imágenes existentes
                setHeaderImagePreview({ 
                    preview: projectData.header_image, 
                    name: 'IMAGEN-GRANDE.webp' 
                });
                setMainImagePreview({ 
                    preview: projectData.main_image, 
                    name: `1_${projectData.name}.webp` 
                });
                setThumbnailImagePreview({ 
                    preview: `/img/PROJECTS/${projectData.name.toUpperCase().replace(/\s+/g, '-')}.webp`, 
                    name: `${projectData.name.toUpperCase().replace(/\s+/g, '-')}.webp` 
                });
    
                const layoutImages = projectData.layouts.map(layout => {
                    const images = JSON.parse(layout.image_group);
                    return images.map(img => ({
                        preview: img.src,
                        name: extractFileName(img.src, projectData.name.toUpperCase().replace(/ /g, '_')), // Guardar solo el nombre del archivo
                        size: img.size
                    }));
                });

                setImagePreviews(layoutImages);
    
            } catch (error) {
                console.error('Error fetching project data:', error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };
    
        if (show && project?.id) {
            fetchData();
        }
    }, [show, project, apiUrl, token]);
    
    
    // También asegúrate de modificar handleImageSizeChange si es necesario
    const handleImageSizeChange = (newSize, groupIndex, index) => {
        const updatedImagePreviews = [...imagePreviews];
        updatedImagePreviews[groupIndex][index].size = newSize;
        setImagePreviews(updatedImagePreviews);
    };

    const handleImageChange = (file, groupIndex, index) => {
        const fileExtension = file.name.split('.').pop(); // Revisa la extensión original
        console.log('File extension:', fileExtension);
        const updatedPreview = URL.createObjectURL(file);

        const updatedImages = [...imagePreviews];
        const originalName = updatedImages[groupIndex][index].name;

        updatedImages[groupIndex][index] = { ...updatedImages[groupIndex][index], file, preview: updatedPreview };
        setImagePreviews(updatedImages);

        const updatedChangedImages = [...changedImages];
        const existingImageIndex = updatedChangedImages.findIndex(img => img.groupIndex === groupIndex && img.index === index);

        if (existingImageIndex !== -1) {
            updatedChangedImages[existingImageIndex] = { groupIndex, index, file, originalName };
        } else {
            updatedChangedImages.push({ groupIndex, index, file, originalName });
        }

        setChangedImages(updatedChangedImages);
    };

    const updateProjectDetails = async () => {
        const projectData = {
            location,
            surface,
            description1,
            description2,
        };
    
        if (
            projectData.location === project.location &&
            projectData.surface === project.surface &&
            projectData.description1 === project.description1 &&
            projectData.description2 === project.description2
        ) {
            console.log('No se han realizado cambios en los detalles del proyecto');
            return;
        }
    
        console.log('Enviando datos del proyecto:', projectData);
    
        const response = await fetch(`${apiUrl}/api/admin/projects/updateById/${project.id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(projectData),
        });
    
        if (!response.ok) {
            const errorResponse = await response.json();
            console.error('Error en la actualización del proyecto:', errorResponse);
            throw new Error(errorResponse.error || 'Error al actualizar los detalles del proyecto');
        }
    };

    const uploadChangedImages = async (changedImages, projectName, useOverwrite = true) => {
        const projectNameForPath = projectName.toUpperCase().replace(/ /g, '_');
        
        for (const image of changedImages) {
            console.log('Uploading changed image:', image);
            const { file, originalName } = image;
    
            // Extraer la extensión del archivo que está subiendo el usuario
            const fileExtension = file.name.split('.').pop();
    
            // Reemplazar la extensión actual en el originalName con la extensión del archivo subido
            const finalName = originalName.replace(/\.[^/.]+$/, `.${fileExtension}`);
    
            console.log('Final file name for upload:', finalName);
    
            // Usa la nueva función uploadImageWithOverwriteOption para manejar la lógica de subida
            await uploadImageWithOverwriteOption(file, projectNameForPath, originalName, useOverwrite);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError(null);
        try {
            await updateProjectDetails();
    
            // Si hay imágenes cambiadas, súbelas al servidor
            if (changedImages.length > 0) {
                await uploadChangedImages(changedImages, project.name);
            }
            console.log('Proyecto actualizado correctamente');
            handleClose();
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error al actualizar el proyecto:', error);
            setError('Hubo un error al actualizar el proyecto. Por favor, inténtelo de nuevo.');
            setShowErrorModal(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSuccessModalClose = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    const editButton = (color) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" >
                <path fill={color} d="M16.293 2.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-13 13A1 1 0 0 1 8 21H4a1 1 0 0 1-1-1v-4a1 1 0 0 1 .293-.707l10-10zM14 7.414l-9 9V19h2.586l9-9zm4 1.172L19.586 7L17 4.414L15.414 6z"/>
            </svg>
        )
      }

    const returnButton = (color) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                    <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/>
                    <path fill={color} d="M3.076 5.617A1 1 0 0 1 4 5h10a7 7 0 1 1 0 14H5a1 1 0 1 1 0-2h9a5 5 0 1 0 0-10H6.414l1.793 1.793a1 1 0 0 1-1.414 1.414l-3.5-3.5a1 1 0 0 1-.217-1.09"/>
                </g>
            </svg>
        );  
    };

    const SuccessSVG = (color='#fafafa') => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="350px" height="350px" viewBox="0 0 48 48"><defs><mask id="IconifyId191a55f96413d28330"><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="4">
                <path fill='red' stroke="fff" d="m24 4l5.253 3.832l6.503-.012l1.997 6.188l5.268 3.812L41 24l2.021 6.18l-5.268 3.812l-1.997 6.188l-6.503-.012L24 44l-5.253-3.832l-6.503.012l-1.997-6.188l-5.268-3.812L7 24l-2.021-6.18l5.268-3.812l1.997-6.188l6.503.012z"/>
                <path stroke="#000" d="m17 24l5 5l10-10"/></g></mask></defs>
                <path fill="currentColor" d="M0 0h48v48H0z" mask="url(#IconifyId191a55f96413d28330)"/>
            </svg>
        )
    };

    const renderImageGroups = () => {
        return imagePreviews.map((imageGroup, groupIndex) => (
            imageGroup.map((img, index) => {
                const imageIndex = `${groupIndex}-${index}`;
                const isOpen = openIndexes.includes(imageIndex);
                console.log('Image preview:', img);
                return (
                    <Col 
                        md={6} 
                        style={{ position: 'relative', display: 'flex', flexDirection: 'column', padding: '10px', height: '330px' }} 
                        key={imageIndex}
                    >
                        {isOpen ? (
                            <>
                                <ImageUploadButton
                                    labelClassName={`Edit`}
                                    imageFile={img.file}
                                    setImageFile={(file) => handleImageChange(file, groupIndex, index)}
                                    fileName={img.name}
                                    preview={img.preview}
                                    onImageChange={(file) => handleImageChange(file, groupIndex, index)}
                                />
                                <Form.Control
                                    type="number"
                                    defaultValue={img.size}
                                    name={`formProjectImageSize${index}`}
                                    onChange={(event) => handleImageSizeChange(event.target.value, groupIndex, index)}
                                    style={{ display:'flex', alignSelf:'center', marginTop: '5px', maxWidth:'50%', textAlign: 'center' }}
                                />
                                <Button
                                    variant="secondary"
                                    className='aux-button-modal'
                                    onClick={() => handleImageEdit(groupIndex, index)}
                                >
                                    {returnButton('#6e132a')}
                                </Button>
                            </>
                        ) : (
                            <>
                                <LazyLoadImage 
                                    style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}}
                                    src={`${img.preview}?v=${new Date().getTime()}`} 
                                    alt={img.name}
                                    className='other-images-previews'
                                />
                                <Button
                                    variant="secondary"
                                    className='aux-button-modal'
                                    onClick={() => handleImageEdit(groupIndex, index)}
                                >
                                    {editButton('#6e132a')}
                                </Button>
                            </>
                        )}
                    </Col>
                );
            })
        ));
    };
    
    

    return (
        <>
            {/* Modal de Carga */}
            {isLoading && (
                <Modal show={true} backdrop="static" keyboard={false}>
                    <Modal.Body>
                        <Loader />
                    </Modal.Body>
                </Modal>
            )}
    
            {/* Modal de Error */}
            {showErrorModal && (
                <Modal show={true} onHide={() => setShowErrorModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{error}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            {/* Modal de Éxito */}
            {showSuccessModal && (
                <Modal show={true} onHide={handleSuccessModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Éxito</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{alignSelf:'center'}}>
                        <SuccessSVG color='#059669'/>
                        <p>El proyecto se ha actualizado correctamente.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='modal-button-save' variant="secondary" onClick={handleSuccessModalClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
    
            {/* Modal Principal */}
            <Modal className='edit-project-modal' show={show} onHide={handleClose} backdrop={false} keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Proyecto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? (
                        <Loader />
                    ) : project && (
                        <Form ref={formRef}>
                            <FormField
                                controlId="formProjectName"
                                label="Nombre"
                                type="text"
                                placeholder="Nombre"
                                readOnly
                                defaultValue={project?.name}
                            />
                            <ImageUploadButton 
                                label="Imagen de Cabezera " 
                                labelClassName="Header"
                                imageFile={headerImageFile} 
                                setImageFile={setHeaderImageFile}
                                fileName={`img/${project.name.toUpperCase().replace(/ /g, '_')}/IMAGEN-GRANDE.webp`}
                                preview={headerImagePreview?.preview}  
                                onImageChange={handleImageChange}
                            />
                            <ImageUploadButton
                                label="Imagen de Miniatura"
                                labelClassName="Thumbnail"
                                imageFile={thumbnailImageFile}
                                setImageFile={setThumbnailImageFile}
                                fileName={`img/PROJECTS/${project.name.toUpperCase().replace(/ /g, '-')}.webp`}
                                preview={thumbnailImagePreview?.preview}
                                onImageChange={handleImageChange}
                            />
                            <FormField
                                controlId="formProjectLocation"
                                label="Ubicación"
                                type="text"
                                placeholder="Ubicación"
                                value={location}
                                onChange={(event) => setLocation(event.target.value)}
                            />
                            <FormField
                                controlId="formProjectSurface"
                                label="Superficie (m²)"
                                type="text"
                                placeholder="Superficie"
                                value={surface}
                                onChange={(event) => setSurface(event.target.value)}
                            />
                            <ImageUploadButton 
                                label="Imagen Principal" 
                                labelClassName="Main"
                                imageFile={mainImageFile} 
                                setImageFile={setMainImageFile}
                                fileName={`img/${project.name.toUpperCase().replace(/ /g, '_')}/1_${project.name.toUpperCase().replace(/ /g, '_')}.webp`}
                                preview={mainImagePreview?.preview}
                                onImageChange={handleImageChange}
                            />
                            <FormField
                                controlId="formProjectDescription1"
                                label="Descripción 1"
                                type="text"
                                placeholder="Descripción 1"
                                as="textarea"
                                rows={5}
                                value={description1}
                                onChange={(event) => setDescription1(event.target.value)}
                            />
                            <FormField
                                controlId="formProjectDescription2"
                                label="Descripción 2"
                                type="text"
                                placeholder="Descripción 2"
                                as="textarea"
                                rows={5}
                                value={description2}
                                onChange={(event) => setDescription2(event.target.value)}
                            />
                            <Form.Group controlId="formProjectImages">
                                <Form.Label>Otras Imágenes</Form.Label>
                                <Row style={{ rowGap: '12px' }}>
                                    {renderImageGroups()}
                                </Row>
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button className='modal-button-quit' onClick={handleClose}>
                        {returnButton('#fafafa')}
                    </Button>
                    <Button className='modal-button-save' type='button' onClick={handleSubmit}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditProjectModal;