import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import PanelControl from './screens/ADMIN/CONTROL_PANEL/ControlPanel';
import PrivateRoute from './screens/ADMIN/CONTROL_PANEL/PrivateRoute';
import { useDynamicRoutes } from './utils/dynamicRoutes';
import HomePage from './screens/HomePage/HomePage';
import Projects from './screens/Projects/Projects';
import Services from './screens/Services/Services';
import ContactPage from './screens/ContactPage/ContactPage';
import Loader from './components/Loader';
import { DynamicRoutesContext } from './utils/dynamicRoutesContext'; 

function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return null;
}

const App = () => {
    const { dynamicRoutes, isLoading } = useDynamicRoutes();

    return (
        <DynamicRoutesContext.Provider value={dynamicRoutes}>
            <Router>
                <ScrollToTop />
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/proyectos" element={<Projects />} />
                        <Route path="/servicios" element={<Services />} />
                        <Route path="/contacto" element={<ContactPage />} />
                        <Route path="/admin/control-panel" element={<PrivateRoute component={PanelControl} />} />
                        {isLoading ? (
                            <Route path="*" element={<Loader />} />
                        ) : (
                            dynamicRoutes.map((route, index) => (
                                <Route key={index} path={route.path} element={route.element} />
                            ))
                        )}
                    </Routes>
                </Suspense>
            </Router>
        </DynamicRoutesContext.Provider>
    );
};

export default App;