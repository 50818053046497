import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const renderSocialMediaLogo = () => (
    <div>
        <LazyLoadImage 
            src="/img/SERVICIOS/LOGOS-REDES.webp" 
            alt="Logos Redes Sociales" 
            className='social-media-logos'
            useMap='#socialMap' 
        />
        <map name="socialMap">
            <area target="_blank" alt="instagram" title="instagram" href="https://www.instagram.com/estudioregular" coords="2,3,48,48" shape="rect" rel="noopener noreferrer" />
            <area target="_blank" alt="facebook" title="facebook" href="https://www.facebook.com/estudioregular" coords="103,24,24" shape="circle" rel="noopener noreferrer" />
            <area target="_blank" alt="youtube" title="youtube" href="https://www.youtube.com/@estudioregular" coords="181,25,24" shape="circle" rel="noopener noreferrer" />
        </map>
    </div>
);

export default renderSocialMediaLogo;