import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function SavedGroups({ savedGroups, handleImagesSizeChange, removeGroup, handleSubmitSelection, images }) {
    
    const renderImage = (image, groupIndex, imageIndex) => (
        <Col xs={4} key={`${groupIndex}-${imageIndex}`} className='tiny-image'>
            <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} src={image.preview || image.src} alt={image.alt} />
            <span style={{ color: '#fafafa', margin: '0 2px' }}>*</span>
            <input
                className='image-size-input'
                type='number'
                value={image.size}
                onChange={(e) => handleImagesSizeChange(e.target.value, groupIndex, imageIndex)}
            />
        </Col>
    );

    const renderGroup = (group, groupIndex) => (
        <Container className="saved-group" key={groupIndex}>
            <Row style={{ justifyContent: 'center' }}>
                <h5 style={{ width: 'max-content', color: '#fafafa' }}>Layout {group.layout}</h5>
            </Row>
            <Row style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                {group.images.map((image, imageIndex) => renderImage(image, groupIndex, imageIndex))}
                <Button className='delete-group-button' onClick={() => removeGroup(groupIndex)}>Eliminar Grupo</Button>
            </Row>
        </Container>
    );

    const renderDescription = () => (
        <Col xs={8} style={{ margin: '0px auto' }}>
            <span style={{ maxWidth: '80%' }}>
                * El número es el tamaño por defecto de la imagen. El bloque contiene 12 espacios,
                si por ejemplo el tamaño de todas las imágenes es de 4, se verán en una fila una al lado
                de otra como en la imagen del layout (excepto el layout 2). La suma total siempre debe ser 12.
            </span>
        </Col>
    );

    return (
        <Container className='images-preview'>
            {savedGroups.map((group, groupIndex) => renderGroup(group, groupIndex))}
            
            <Col xs={12} style={{ display: 'flex', margin: '10px auto' }}>
                <Button 
                    className='select-button' 
                    onClick={handleSubmitSelection}
                    disabled={images.length === 0}
                    title={images.length === 0 ? 'Ya no se pueden guardar más imágenes' : ''}
                >
                    Guardar Selección
                </Button>
            </Col>
            
            {savedGroups.length > 0 && renderDescription()}
        </Container>
    );
}

export default SavedGroups;