import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import './CarteraDeServicios.css';

const Card = ({ children }) => {
    return (
        <article className={`rounded-container`}>
            <section className='space-between'>
                {children}
            </section>
        </article>
    );
};

const CarteraDeServicios = () => {
    const [expandedOption, setExpandedOption] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleDownloadClick = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        try {
            const response = await fetch('/CARTERA_DE_SERVICIOS.pdf');
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'CARTERA_DE_SERVICIOS.pdf';
            a.target = '_blank';  // Abrir en una nueva ventana
            a.click();
            window.URL.revokeObjectURL(url);  // Liberar la URL creada
        } catch (error) {
            console.error('Error al descargar el PDF:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOptionClick = (option) => {
        if (expandedOption.includes(option)) {
            setExpandedOption(expandedOption.filter(item => item !== option));
        } else {
            setExpandedOption([...expandedOption, option]);
        }
    };

    const categories = [
        {
            category: 'DISEÑO',
            options: [
                'Anteproyecto',
                'Proyecto de Arquitectura'
            ]
        },
        {
            category: 'ESPECIALIDADES',
            options: [
                'Proyecto de Cálculo Estructural',
                'Proyecto Sanitario',
                'Proyecto Electricidad'
            ]
        },
        {
            category: 'TRAMITACIONES',
            options: [
                'Permisos de Edificación',
                'Solicitud de Demolición',
                'Informe Favorable de Construcción',
                'Calificación Industrial',
                'Informe de Acondicionamiento Térmico (o PDA)',
                'Declaración TE1',
                'Resolución Sanitaria',
            ]
        }
    ];

    const contentData = {
        "Anteproyecto": {
            details: [
                "Visitas a terreno",
                "3 Reuniones con mandantes",
                "Elaboración de proyecto conceptual",
                "3 Modificaciones y rectificaciones de modelo",
                "Planos informativos formato digital",
                "5 Renders interiores y 5 exteriores"
            ],
            videoId: "TcTBVOSiFm4"
        },
        "Proyecto de Arquitectura": {
            details: [
                "Plano de emplazamiento",
                "Cuadros de superficie y esquemas",
                "Plantas de arquitectura 1er y 2do nivel",
                "Planta de fundaciones",
                "Planta de cubiertas",
                "Elevaciones y cortes",
                "Planta estructura techumbres",
                "Modelos Gráficos 2d",
                "Plano de puertas y ventanas",
                "Plano de revestimientos",
                "Planta de entrepiso",
                "Escantillones",
                "Plano de escaleras",
                "Detalles constructivos",
                "5 Renders interiores y 5 exteriores",
                "Expediente"
            ],
            videoId: "10kMBycp0_0"
        },
        "Proyecto de Cálculo Estructural": {
            details: [
                "Planos de estructuras",
                "Memoria de cálculo",
                "Planos de detalles y uniones"
            ],
            videoId: "mJBFg6dGFyE"  
        },
        "Proyecto Sanitario": {
            details: [
                "Planos de Agua potable",
                "Memoria de Alcantarillado",
                "Memorias de cálculo"
            ],
            videoId: "HpU4QdOAYvA"  
        },
        "Proyecto Electricidad": {
            details: [
                "Planos de lámparas",
                "Plano de enchufes",
                "Esquemas, cuadros, protecciones"
            ],
            videoId: "xfC4lzeJT8Q"  
        },
        "Permisos de Edificación": {
            details: [
                "Ampliaciones",
                "Habilitaciones",
                "Cambios de destino",
                "Recepción final",
                "Ley del mono",
                "Modificaciones",
                "Obra nueva",
                "Regularizaciones"
            ],
            videoId: "dtLwwDccV4o" 
        },
        "Solicitud de Demolición": {
            details: null,
            videoId: "5zigPApnBKU" 
        },
        "Informe Favorable de Construcción": {
            details: [
                "Informe Favorable de Construcciones"
            ],
            videoId: "LNqLHrl5bEc" 
        },
        "Informe de Acondicionamiento Térmico (o PDA)": {
            details: null,
            videoId: "4UZdOe8cyGE" 
        },
        "Calificación Industrial": {
            details: null,
            videoId: "I09hV7dqj9s" 
        },
        "Declaración TE1": {
            details: null,
            videoId: "xfC4lzeJT8Q" 
        },
        "Resolución Sanitaria": {
            details: [
                "Aprobación y recepción de proyectos Sanitarios"
            ],
            videoId: "wPupoBz6FLY" 
        }
    };

    const ListSVG = ({ color, className }) => (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <path fill={color} d="m14.475 12l-7.35-7.35q-.375-.375-.363-.888t.388-.887t.888-.375t.887.375l7.675 7.7q.3.3.45.675t.15.75t-.15.75t-.45.675l-7.7 7.7q-.375.375-.875.363T7.15 21.1t-.375-.888t.375-.887z"/>
        </svg>
    );

    const ListItemSVG = ({ color, className }) => (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width=".7em" height=".7em" viewBox="0 0 24 24">
            <path fill={color} d="M20.788 3.832c-.101-.105-.197-.213-.301-.317q-.155-.153-.32-.302A11.9 11.9 0 0 0 12 0a11.93 11.93 0 0 0-8.486 3.514C-1.062 8.09-1.16 15.47 3.213 20.168q.147.162.3.32c.104.103.21.2.317.3A11.92 11.92 0 0 0 12 24c3.206 0 6.22-1.247 8.487-3.512c4.576-4.576 4.673-11.956.301-16.656m-16.655.301A11.06 11.06 0 0 1 12 .874c2.825 0 5.49 1.048 7.55 2.958l-1.001 1.002A9.65 9.65 0 0 0 12 2.292a9.64 9.64 0 0 0-6.865 2.844A9.64 9.64 0 0 0 2.292 12c0 2.448.9 4.753 2.542 6.549L3.831 19.55C-.201 15.191-.101 8.367 4.133 4.133m13.798 1.318v.002l-1.015 1.014A7.35 7.35 0 0 0 12 4.589A7.36 7.36 0 0 0 6.761 6.76A7.36 7.36 0 0 0 4.589 12a7.34 7.34 0 0 0 1.877 4.913l-1.014 1.016A8.77 8.77 0 0 1 3.167 12a8.77 8.77 0 0 1 2.588-6.245A8.77 8.77 0 0 1 12 3.167c2.213 0 4.301.809 5.931 2.284M18.537 12c0 1.745-.681 3.387-1.916 4.622S13.746 18.538 12 18.538a6.5 6.5 0 0 1-4.296-1.621l-.001-.004c-.11-.094-.22-.188-.324-.291a6 6 0 0 1-.293-.326A6.47 6.47 0 0 1 5.466 12c0-1.746.679-3.387 1.914-4.621A6.5 6.5 0 0 1 12 5.465c1.599 0 3.105.576 4.295 1.62c.111.096.224.19.326.295q.154.157.295.324A6.48 6.48 0 0 1 18.537 12M7.084 17.534h.001A7.35 7.35 0 0 0 12 19.413a7.35 7.35 0 0 0 5.239-2.174A7.35 7.35 0 0 0 19.412 12a7.36 7.36 0 0 0-1.876-4.916l1.013-1.012A8.78 8.78 0 0 1 20.834 12a8.77 8.77 0 0 1-2.589 6.246A8.76 8.76 0 0 1 12 20.834a8.78 8.78 0 0 1-5.93-2.285zm12.783 2.333A11.05 11.05 0 0 1 12 23.125a11.04 11.04 0 0 1-7.551-2.957l1.004-1.001a9.64 9.64 0 0 0 6.549 2.542a9.64 9.64 0 0 0 6.865-2.846A9.64 9.64 0 0 0 21.71 12a9.64 9.64 0 0 0-2.543-6.548l1.001-1.002c4.031 4.359 3.935 11.182-.301 15.417"/>
        </svg>
    );

    const DownloadIcon = ({color, className}) => (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
            <path fill={color} d="M19.31 12.051c.381 0 .69.314.69.7v4.918c-.006.67-.127 1.2-.399 1.594c-.328.476-.908.692-1.747.737l-15.903-.002c-.646-.046-1.168-.302-1.507-.777c-.302-.423-.446-.95-.444-1.558V12.75c0-.386.309-.7.69-.7s.688.314.688.7v4.913c0 .333.065.572.182.736c.081.114.224.184.44.201l15.817.001c.42-.023.627-.1.655-.14c.084-.123.146-.393.15-.8V12.75c0-.386.308-.7.689-.7M9.99 0c.38 0 .69.313.69.7l-.001 10.869l3.062-3.079a.68.68 0 0 1 .975.004a.707.707 0 0 1-.004.99l-4.356 4.38a.68.68 0 0 1-.973-.003l-4.296-4.38a.707.707 0 0 1 .002-.99a.68.68 0 0 1 .975.002L9.3 11.794V.699C9.3.313 9.61 0 9.99 0"/>
        </svg>
    )

    return (
        <Container fluid>
            <Row>
                {categories.map((cat, index) => (
                    <Col xs={12} xl={4} key={index} className="card-content">
                        <Card >
                            <header>
                                <h3 className='name-card'>{cat.category}</h3>
                            </header>
                            <article>
                                <ul className='menu-options'>
                                    {cat.options.map((option, idx) => (
                                        <li key={idx} onClick={() => handleOptionClick(option)} className={expandedOption.includes(option) ? 'menu-option-item-active' : 'menu-option-item'}>
                                        <header className={expandedOption.includes(option) ?  'header-option-active':'header-option'}>
                                            <h1 className={expandedOption.includes(option) ?  'option-active' : 'option'}>{option}</h1> 
                                            <ListSVG color={expandedOption.includes(option) ? '#6e132a':'#87767a'} className={expandedOption.includes(option) ?  'list-icon-menu-option':'list-icon-menu-option-active'} />
                                        </header>
                                        {expandedOption.includes(option) && (
                                            <main className="expanded-content-container">
                                                {contentData[option]?.details && (
                                                    <ul className="expanded-content">
                                                        {contentData[option]?.details.map((detail, index) => (
                                                            <li key={index}>
                                                                <ListItemSVG className='list-icon' color={"#87767a"} />
                                                                {detail}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                                {contentData[option]?.videoId && (
                                                    <div className="video-container">
                                                        <iframe 
                                                            width="90%" 
                                                            height="200px" 
                                                            src={`https://www.youtube.com/embed/${contentData[option].videoId}`} 
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                            allowFullScreen
                                                        ></iframe>
                                                    </div>
                                                )}
                                            </main>
                                        )}
                                    </li>
                                    ))}
                                </ul>
                            </article>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Container className="pdf-download-section">
                <a 
                    href="/CARTERA_DE_SERVICIOS.pdf" 
                    download 
                    className="pdf-download-button" 
                    onClick={handleDownloadClick}
                    style={{ cursor: isLoading ? 'progress' : 'pointer' }} // Cambia el cursor durante la descarga
                >
                    <DownloadIcon color="#87767a" className='download-icon' />
                    Descargar PDF Cartera de Servicios
                </a>
            </Container>
        </Container>
    );
};

export default CarteraDeServicios;