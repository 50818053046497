import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function ImageUploadButton({ label, labelClassName, imageFile, setImageFile, fileName, preview, onImageChange }) {
    const [localPreview, setLocalPreview] = useState(preview);

    const onDrop = (acceptedFiles) => {
        try {
            const file = acceptedFiles[0];
            const previewUrl = URL.createObjectURL(file);
            setLocalPreview(previewUrl);

            // Extraer la extensión original del archivo
            const originalExtension = file.name.split('.').pop();

            // Crear un nuevo archivo con el nombre adecuado y la extensión original
            const newFileName = fileName.replace(/\.webp$/, `.${originalExtension}`);
            const newFile = new File([file], newFileName, { type: file.type });

            // Asignar solo el link sin el prefijo "blob:"
            Object.defineProperty(newFile, 'blob', {
              writable: true,
              value: previewUrl
            });

            setImageFile(newFile);
            onImageChange(newFile, previewUrl);
      } catch (error) {
            console.error(`Error al subir la imagen ${label}:`, error);
      }
    };

    const dropzone = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/webp': [],
            'image/svg+xml': []
        },
        onDrop,
    });

    // Limpiar URL de vista previa al desmontar el componente para evitar fugas de memoria
    useEffect(() => {
        return () => {
            if (localPreview) {
                URL.revokeObjectURL(localPreview);
            }
        };
    }, [localPreview]);

    useEffect(() => {
        setLocalPreview(preview);
    }, [preview]);

    const inputId = `form${labelClassName}Image`; // Define el id

    return (
        <Form.Group>
            <Form.Label 
                className={`${label ? '' : 'label-no-display'}`} 
                htmlFor={inputId}>
                  {label ? label : null}
            </Form.Label>
            <div {...dropzone.getRootProps()} className={`${labelClassName.toLowerCase()} dropzone dropzone-container-images`}>
                <input {...dropzone.getInputProps({ id: inputId })} />
                <Button className='button-upload-image'>
                    {imageFile || preview ? 'Cambiar Imagen' : 'Subir Imagen'}
                </Button>
                {localPreview ? (
                    <>
                        <p style={{color:'#6e132a', margin:'15px auto'}}>{fileName}</p>
                        <LazyLoadImage
                            src={localPreview}
                            alt={fileName}
                            className="image-preview"
                            style={{ maxWidth: '450px', height: 'auto', margin:'5px auto', display:'block', borderRadius:'5px'}}
                        />
                    </>
                ) : null}
            </div>  
        </Form.Group>
    );
}

export default ImageUploadButton;