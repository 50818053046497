import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

const InfoSection = ({ children, upperText1, upperText2, lowerText, windowWidth, className, containerName, view }) => {
    return (
        <Container className={`${containerName} my-5`}>
            <Row>
                {windowWidth < 1201 || view === 'services' ? (
                    <>
                        <Col xs={12} md={12} style={{textAlign: 'center'}}>
                            <h1 className="upper-text">{upperText1}<br />{upperText2}</h1>
                        </Col>
                        <Col className={className} xs={12} md={12} style={{textAlign: 'center'}}>
                            {children}
                        </Col>
                        <Col xs={12} md={12} style={{textAlign: 'center'}}>
                            <p className='lower-text'>{lowerText}</p>
                        </Col>
                    </>
                ) : (
                    <>
                        <Col className={className} xs={12} md={12} xl={5} style={{textAlign: 'right'}}>
                            {children}
                        </Col>
                        <Col className='rigth-section' xs={12} md={12} xl={6} style={{textAlign: 'right'}}>
                            <h1 className="upper-text">{upperText1}<br />{upperText2}</h1>
                            <p className='lower-text' >{lowerText}</p>
                        </Col>
                    </>
                )}
            </Row>
        </Container>
    );
};

export default InfoSection;
