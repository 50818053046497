export const runBuildAndDeploy = () => {
    console.log('Running npm run build...');

    exec('npm run build', { cwd: path.join(__dirname, '..', 'frontend') }, (err, stdout, stderr) => {
        if (err) {
            console.error('Error during build:', stderr);
            return;
        }

        console.log('Build successful. Deploying to public_html...');

        const source = path.join(__dirname, '..', 'frontend', 'build');
        const destination = '/path_to_public_html';

        exec(`cp -r ${source}/* ${destination}`, (err, stdout, stderr) => {
            if (err) {
                console.error('Error during deployment:', stderr);
                return;
            }
            console.log('Deployment successful!');
        });
    });
};