// FollowUs.jsx
import React from 'react';
import Col from 'react-bootstrap/Col';
import renderSocialMediaLogo from '../../components/RenderSocialMediaLogos';

const FollowUs = ({className}) => {
    return (
        <Col xs={12} md={12} xl={12}>
            <h3 className={className}>¡¡SÍGUENOS EN NUESTRAS REDES!!</h3>
            <Col style={{ textAlign: 'center' }}>{renderSocialMediaLogo()}</Col>
        </Col>
    );
};

export default FollowUs;