import React from 'react';
import ItemSection from './ItemSection';
import AddServiceModal from '../Create/AddServiceModal';
import EditServiceModal from '../EditModal/EditServiceModal';
import DeleteModal from '../DeleteModal/DeleteModal';
import Row from 'react-bootstrap/Row';
import CardAsesoria from '../../../Services/CardAsesoria'; 
import './ServiceSection.css';

function ServiceSection() {
    const renderServiceCardContent = (service) => (
        <Row>
            <CardAsesoria 
                title={service.title}
                description={service.description}
                price={service.price}
                duration={service.duration}
                report={service.report_type}
                mode={service.mode}
            />
        </Row>
    );

    return (
        <ItemSection
            title="Servicios"
            apiUrl={process.env.REACT_APP_API_URL}
            fetchUrl="api/admin/services/advisories/getAll"
            renderCardContent={renderServiceCardContent}
            CreateModal={AddServiceModal}
            EditModal={EditServiceModal}
            DeleteModal={DeleteModal}
            classContainer='services-section'
            classCard='service-card'
            item_width={10}
        />
    );
}

export default ServiceSection;
