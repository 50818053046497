// MasterPlanSection.jsx
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ActionItem from './ActionItem';
import CustomButtom from '../../components/CustomButton';
import SectionTitle from '../HomePage/SectionTitle';
import './MasterPlanSection.css';

const MasterPlanSection = ({ text_master_plan, onclick }) => {
    return (
        <>
            <SectionTitle className="section-title-rest" title='MASTER PLAN'/>
            <Row>
                <Col xs={12} md={12} xl={6}>
                    <LazyLoadImage style={{maxWidth:'100%', height:'auto', display:'block', borderRadius:'5px'}} src="img/SERVICIOS/IMAGEN-MASTER-PLAN.webp" alt="Master Plan Pichoy - Valdivia" />
                    <h1 className="master-plan-caption">MASTER PLAN PICHOY - VALDIVIA</h1>
                </Col>
                <Col xs={12} md={12} xl={6} className='center-button'> 
                    <h3 className="master-plan-text">{text_master_plan}</h3>
                    <CustomButtom className='master-plan-action-button' onclick={onclick}>
                        ¡Comencemos!
                    </CustomButtom>
                    <Row className='row-action-item'>
                        <ActionItem text="Rectificación de Deslindes" />
                        <ActionItem text="División & Fusión" />
                        <ActionItem text="Master Plan" />
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default MasterPlanSection;