import React, { useEffect, useState, useMemo, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDropzone } from 'react-dropzone';
import FormField from '../FormField';
import ImagePreview from './ImagePreview';
import ImageUploadButton from './ImageUploadButton';
import './CreateProject.css';


function CreateProject({ setActiveKey, onImagesChange, onProjectDataChange }) {
  const [projectName, setProjectName] = useState('');
  const headerImageFileRef = useRef(null);
  const mainImageFileRef = useRef(null);
  const thumbnailImageFileRef = useRef(null);
  const [location, setLocation] = useState('');
  const [area, setArea] = useState('');
  const [description1, setDescription1] = useState('');
  const [description2, setDescription2] = useState('');
  const [imagesCounter, setImagesCounter] = useState(0);
  const [acceptedImages, setAcceptedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
    const formattedProjectName = projectName.toUpperCase().replace(/ /g, '_');
    const headerFileName = 'IMAGEN-GRANDE.webp';
    const mainFileName = `1_${formattedProjectName}.webp`;
  
    // Actualiza los nombres de los archivos
    if (headerImageFileRef.current) {
      Object.defineProperty(headerImageFileRef.current, 'name', { writable: true, value: headerFileName });
    }
    if (mainImageFileRef.current) {
      Object.defineProperty(mainImageFileRef.current, 'name', { writable: true, value: mainFileName });
    }
    if (thumbnailImageFileRef.current) {
      Object.defineProperty(thumbnailImageFileRef.current, 'name', { writable: true, value: thumbnailFileName });
    }
  }, [projectName, headerImageFileRef, mainImageFileRef, thumbnailImageFileRef]);

  const handleProjectNameChange = (e) => setProjectName(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleAreaChange = (e) => setArea(e.target.value);
  const handleDescription1Change = (e) => setDescription1(e.target.value);
  const handleDescription2Change = (e) => setDescription2(e.target.value);
  const handleHeaderImageChange = (file) => headerImageFileRef.current = file;
  const handleMainImageChange = (file) => mainImageFileRef.current = file;
  const handleThumbnailImageChange = (file) => thumbnailImageFileRef.current = file;

  const dropzoneOptions = useMemo(() => ({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
      'image/svg+xml': []
    },
    onDrop: (acceptedFiles, fileRejections) => {
      if (!projectName) {
        alert('Por favor, ingrese un nombre de proyecto');
        return;
      }
      fileRejections.forEach(fileRejection => {
        console.error(`El archivo ${fileRejection.file.name} fue rechazado porque no es una imagen.`);
      });
      const newImagePreviews = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      setImagesCounter(imagesCounter + acceptedFiles.length);
      setAcceptedImages(acceptedImages.concat(acceptedFiles));
      setImagePreviews(prev => [...prev, ...newImagePreviews]);
      if (typeof onImagesChange === 'function') {
        onImagesChange(acceptedImages.concat(acceptedFiles));
      }
    }
  }), [projectName, acceptedImages, imagesCounter, onImagesChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions);

  const removeImage = (index) => {
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    setImagePreviews(updatedPreviews);
    setImagesCounter(imagesCounter - 1);
    if (typeof onImagesChange === 'function') {
      onImagesChange(updatedPreviews);
    }
  };

  const handleSave = (event) => {
    event.preventDefault();
    if (projectName && imagesCounter > 0) {
        const projectNameForPath = projectName.toUpperCase().replace(/ /g, '_');
        const newProjectName = projectName.toUpperCase().replace(/ /g, '-');
        const projectData = {
            name: projectName,
            location: location,
            surface: area,
            description1: description1,
            description2: description2,
            header_image: `/img/${projectNameForPath}/IMAGEN-GRANDE.webp`,
            main_image: `/img/${projectNameForPath}/1_${newProjectName}.webp`,
            thumbnail_image_projects: `/img/PROJECTS/${newProjectName}.webp`,
            thumbnail_image_homepage: `/img/HOMEPAGE/${newProjectName}.webp`,
            headerImageFile: headerImageFileRef.current,
            mainImageFile: mainImageFileRef.current,
            thumbnailImageFile: thumbnailImageFileRef.current
        };

        onProjectDataChange(projectData);
        setActiveKey('selectImages', projectData);
        localStorage.setItem('activeTab', 'selectImages');
    } else {
        alert('Por favor, ingrese el nombre del proyecto y suba al menos una imagen.');
    }
  };

  const dragAndDropStyle = (imagePreviews) => {
    if (imagePreviews.length === 0) {
      return (
        <p style={{
          fontWeight: '700',
          textAlign: 'center',
          color: 'black',
          fontSize: '1.5rem',
          textWrap: 'balance',
          width: '100%',
          margin: '0'
        }}>
          Arrastra y suelta algunos archivos (max 15) aquí <br /> o
        </p>
      );
    }
    return imagePreviews.map((image, index) => (
      <ImagePreview key={index} image={image} index={index} removeImage={removeImage} color='#6e132a'/>
    ));
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveKey('editProjects');
    localStorage.setItem('activeTab', 'editProjects');
  };

  const returnButton = (color) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/>
            <path fill={color} d="M3.076 5.617A1 1 0 0 1 4 5h10a7 7 0 1 1 0 14H5a1 1 0 1 1 0-2h9a5 5 0 1 0 0-10H6.414l1.793 1.793a1 1 0 0 1-1.414 1.414l-3.5-3.5a1 1 0 0 1-.217-1.09"/>
        </g>
    </svg>
  );  

  return (
    <>
    <Container className='create-project'>
      <Row style={{alignItems:'center'}}>
        <Col xs={1}>
          <Button className="button-return" onClick={handleBack}>
              {returnButton('#6e312a')}
          </Button>
        </Col>
        <Col xs={11}>
          <h1 className='title'>Crear Proyecto</h1>
          <p className='subtitle'>Ingrese los datos del proyecto y suba las imágenes necesarias.</p>
        </Col>
      </Row>

      <Col xs={10} className='form-container'>
        <h2 className='title-form'>Datos del Proyecto</h2>
        <Form onSubmit={handleSave}>
          <FormField
            controlId="formProjectName"
            label="Nombre del Proyecto"
            type="text"
            placeholder='Proyecto X'
            value={projectName}
            onChange={handleProjectNameChange}
            required
          />
          <ImageUploadButton 
            label="Imagen de Encabezado" 
            labelClassName="Header"
            imageFile={headerImageFileRef.current}
            setImageFile={handleHeaderImageChange}
            onImageChange={handleHeaderImageChange}
            fileName={`img/${projectName.toUpperCase().replace(/ /g, '_')}/IMAGEN-GRANDE.webp`}
            required
          />
          <ImageUploadButton
            label="Imagen de Miniatura"
            labelClassName="Thumbnail"
            imageFile={thumbnailImageFileRef.current}
            setImageFile={handleThumbnailImageChange}
            onImageChange={handleThumbnailImageChange}
            fileName={`img/PROJECTS/${projectName.toUpperCase().replace(/ /g, '-')}.webp`}
            required
          />
          <FormField
            controlId="formProjectLocation"
            label="Ubicación"
            type="text"
            placeholder="Ubicación"
            onChange={handleLocationChange}
            required
          />
          <FormField
            controlId="formProjectSurface"
            label={<span>Superficie m<sup>2</sup></span>}
            type="text"
            placeholder="Superficie" 
            onChange={handleAreaChange}
            required
          />
          <ImageUploadButton 
            label="Imagen Principal"
            labelClassName="Main"
            imageFile={mainImageFileRef.current}
            setImageFile={handleMainImageChange}
            onImageChange={handleMainImageChange}
            fileName={`img/${projectName.toUpperCase().replace(/ /g, '_')}/1_${projectName.toUpperCase().replace(/ /g, '-')}.webp`}
            required
          />
          <FormField
            controlId="formProjectDescription1"
            label="Descripción 1"
            as="textarea"
            rows={3}
            placeholder="Descripción 1"
            onChange={handleDescription1Change}
            value={description1}
            maxLength={255}
            required
          />
          <FormField
            controlId="formProjectDescription2"
            label="Descripción 2"
            as="textarea"
            rows={3}
            placeholder="Descripción 2"
            onChange={handleDescription2Change}
            value={description2}
            maxLength={255}
            required
          />
          <Form.Group controlId="formUploadImages">
            <Form.Label>Subir Imágenes</Form.Label>
            <Col xs={12} className='drag-and-drop-images' {...getRootProps()}>
              <Form.Control {...getInputProps()} />
              {dragAndDropStyle(imagePreviews)}
              <Button className='button-add'>
                Agregar más
              </Button>
            </Col>
          </Form.Group>
          <Row>
            <Col xs={12} style={{display:'flex', justifyContent:'space-evenly'}}>
              <Button className="button-return" onClick={handleBack}>
                {returnButton('#fafafa')}
              </Button>
              <Button className="button-save" onClick={handleSave}>
                Continuar
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Container>
    </>
  );
}

export default CreateProject;

