
import React from 'react';
import Col from 'react-bootstrap/Col';
import CustomButton from '../../components/CustomButton';


const ButtonBottomSection = ({children, button_name, onclick}) => {
    return (
        <Col xs={6} md={6} className="bottom-block">
            <p className="bottom-title">{children}</p>
            <CustomButton className="btn-bottom" onclick={onclick}>
                {button_name}
            </CustomButton>
        </Col>
    );
}   

export default ButtonBottomSection;