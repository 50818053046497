import { React, useEffect, useState } from 'react';
import { useProjectEffects } from '../../utils/useProjectEffects';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from '../../components/Loader';
import './PROJECTS.css';
import { CardComponent } from '../../components/CustomCarousel/CardComponent';
import { ProjectHeader, ProjectFooter } from '../../components/CustomProject';
import { useDynamicRoutesContext } from '../../utils/dynamicRoutesContext';
import { fetchProjects } from '../../services/projectService';

const Projects = () => {
    const [expanded, setExpanded] = useState(false);
    const [projects, setProjects] = useState([]);
    const windowWidth = useProjectEffects(expanded);
    const storedRoutes = useDynamicRoutesContext();
    const [loading, setLoading] = useState(true); 
    

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await fetchProjects();
                setProjects(data);
            } catch (err) {
                console.error('Error fetching projects:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getRouteForProject = (projectName, routes) => {
        const route = routes.find(route => route.path.includes(projectName));
        return route ? route.path : '';
    };
    
    const renderProjectImagesWithCaption = () => {
        return projects.map((project, i) => {
            const sanitizedProjectName = project.name.toLowerCase().replace(/\s+/g, '-').replace(/ñ/g, 'n');
            const route = getRouteForProject(sanitizedProjectName, storedRoutes);
            return (
                <Col xs={12} md={6} xl={4} key={`img-wrapper-${i}`} className="project-card-container">
                    <CardComponent
                        route={route}
                        src={`/img/PROJECTS/${project.name.replace(/\s+/g, '-').toUpperCase()}.webp`}
                        name={project.name}
                        cardClass="project-card"
                    />
                </Col>
            );
        });
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <ProjectHeader 
                className='projects' 
                title='' 
                backgroundImage={'/img/PROJECTS/CASA-MARKET.webp'}    
            />
            <Container className='projects-view'>
                <Col>
                    <h1 className="title-projects">PROYECTOS</h1>
                </Col>
                <Row className="projects-container">
                    <Col className="images-section">
                        {renderProjectImagesWithCaption()}
                    </Col>
                </Row>
            </Container>
            <ProjectFooter rowClassName='projects-view' />
        </div>
    );
};

export default Projects;