export const fetchServices = async () => {
    const fetchUrl = "api/admin/services/advisories/getAll";
    const token = localStorage.getItem('token');
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${fetchUrl}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
  
    if (!response.ok) {
        throw new Error('Error fetching services');
    }
  
    return response.json();
};    