import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export function ErrorModal({ show, handleHide, errorMessage }) {
    return (
        <Modal show={show} onHide={handleHide} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{errorMessage}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export function ConfirmModal({ show, handleHide, handleConfirm }) {
    return (
        <Modal show={show} onHide={handleHide} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>Confirmación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Desea guardar la selección de imágenes? Esto creará la nueva página del proyecto con las imágenes seleccionadas</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="button-quit" onClick={handleHide}>
                    Cancelar
                </Button>
                <Button className='button-add' onClick={handleConfirm}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export function SuccessModal({ show, handleHide }) {
    return (
        <Modal show={show} onHide={handleHide} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>¡Proyecto creado con éxito!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>El proyecto se ha creado exitosamente. Puedes verlo en la sección de proyectos.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}