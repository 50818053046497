import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function DeleteProjectModal({ show, handleClose, project }) {

    const handleDelete = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${apiUrl}/api/admin/projects/deleteById/${project.id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                console.log('Proyecto eliminado correctamente');
                handleClose(); // Cerrar el modal después de la eliminación exitosa
            } else {
                console.error('Error al eliminar el proyecto');
            }
        } catch (error) {
            console.error('Error al eliminar el proyecto:', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Proyecto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                ¿Estás seguro de que quieres eliminar el proyecto {project?.name}?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteProjectModal;