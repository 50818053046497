import React from 'react';
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './ProjectInfo.css';

const ProjectInfo = ({ className, principal_image, location, surface, text_description_1, text_description_2, text_description_3 }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const colSize = principal_image.size || 4;

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Container className={`${className} my-4`}>
            <Row style={{ marginBottom: '2rem' }}>
                <Col xs={12} xl={colSize}>
                    <LazyLoadImage src={principal_image.src} alt="imagen principal" className='principal-image' />
                </Col>
                <Col xs={12} xl={12 - colSize} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <Row>
                        <Col xs={4} xl={3} style={{paddingRight:'0'}}>
                        <h2 className="left-text-description">
                            Ubicación: <br />Superficie:
                        </h2>
                        </Col>
                        <Col xs={8} xl={9} style={{padding:'0'}}>
                        <h2 className="right-text-description">
                            {location} <br /> {surface} m<sup>2</sup>
                        </h2>
                        </Col>
                    </Row>
                    <h3 className="bottom-text-description">
                        {text_description_1}
                        {text_description_2 && (
                            <>
                                <br /> <br /> {text_description_2}
                            </>
                        )}
                        {text_description_3 && (
                            <>
                                <br /> <br /> {text_description_3}
                            </>
                        )}
                    </h3>
                </Col>
            </Row>
        </Container>
    );
};

export default ProjectInfo;